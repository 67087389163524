import { combineReducers } from 'redux';

import authReducer from './Auth/auth-reducer';
import shopReducer from './Shopping/shopping-reducer';
import brandReducer from './Brand/brand-reducer';
import groupReducer from './Group/group-reducer';
import marqueeReducer from './Marquee/marquee-reducer';
import FAQReducer from './FAQ/faq-reducer';
import articleReducer from './Article/article-reducer';
import metatagReducer from './MetaTags/metatag-reducer';

const rootReducer = combineReducers({
    article: articleReducer,
    metatag: metatagReducer,
    shop: shopReducer,
    auth: authReducer,
    brand: brandReducer,
    group: groupReducer,
    marquee: marqueeReducer,
    FAQ: FAQReducer
});

export default rootReducer;