import React, { 
    useState, 
    useEffect, 
    // useContext 
} from 'react';
import { useNavigate } from 'react-router-dom';

import { Offcanvas } from 'react-bootstrap';
import shoppingCartAPI from '../../../Data/ShoppingCart';
// import NavigationContext from '../../../Context/NavigationContext';

import CustomToast from '../Toast/Toast';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import SecondaryButton from '../SecondaryButton/SecondaryButton';
import EmptyCart from '../EmptyCart/EmptyCart';

import Init from '../../../Init/Init';
import UtilityFunction from '../../../Util/Util';
import './ShoppingCart.scss';

import { connect } from 'react-redux';
import shoppingCartActions from '../../../Redux/Shopping/shopping-action';

const { removeFromCart } = shoppingCartActions;

const { 
    call, 
    // controller 
} = shoppingCartAPI;
const { numberFormatting } = UtilityFunction;

const mapStateToProps = (state) => {
    return {
        cartData: state.shop.cartProducts
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        removeFromCart: (ownerCode, variantId) => dispatch(removeFromCart(ownerCode, variantId))
    }
}

const ShoppingCart = (props) => {
    const [ show, setShow ] = useState(false);
    const [ data, setData ] = useState([]);
    const [ cartCounter, setCartCounter ] = useState(0);

    const { removeFromCart } = props;

    const { FontAwesomeIcon, config } = Init;
    const defaultImage = config.imageFolder + '/placeholder.jpeg';

    const navigate = useNavigate();

    // Offcanvas
    const handleClose = () => {
        // setData([]);
        props.onClose();
    };

    useEffect( () => {
        setCartCounter(props.cartCount);
    }, [props.cartCount] );

    useEffect( () => {
        setShow(props.show);
    }, [props.show] );

    useEffect( () => {
        ( async () => {
            let requestData = {
                id: [],
                size_id: [] 
            };

            const { cartData } = props;

            for(let variantId in cartData) {
                let item = cartData[variantId];

                requestData.id.push(variantId);
                requestData.size_id.push(item.sizeId);
            }

            if(requestData.id.length > 0 && requestData.size_id.length > 0) {
                const response = await call.getCartProducts(requestData.id, requestData.size_id);
                
                if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                    let responseData = response.data.products;
                    for(let index in responseData) {
                        let item = responseData[index];

                        if(cartData.hasOwnProperty(item.id)) {
                            item.quantity = cartData[item.id].quantity;
                        } else {
                            item.quantity = 0;
                        }

                        responseData[index] = item;
                    }

                    setData(responseData);
                   /**
                    * {
                        "data": [
                            {
                                "id": 4,
                                "product_code": "DH7820-700",
                                "variant_name": "Air Jordan 1 Low WMNS Corduroy",
                                "status": 1,
                                "size_category_name": "US Women",
                                "size_name": "8.5W",
                                "photo_path": null
                            },
                            {
                                "id": 21
                                "product_code": "553558-062",
                                "variant_name": "Air Jordan 1 Low Black Grey Pink",
                                "status": 1,
                                "size_category_name": "US",
                                "size_name": "7.5",
                                "photo_path": "553558-062_1.png"
                            }
                        ]
                    }
                    *  */ 
                }
            } else {
                setData([]);
            }
        } )();
    }, [props.show, props.cartCount] );

    const viewCart = () => {
        navigate('/cart', {
            replace: true,
            state: {}
        });
        handleClose();
    };

    const checkout = () => {
        navigate('/checkout', {
            replace: true,
            state: {
                cartData: data
            }
        });
        handleClose();
    }

    const totalPrice = () => {
        let total = data.reduce( (total, item) => {
            let usePrice = item.hasOwnProperty('product_discount_price') && item.product_discount_price > 0 ? parseFloat(item.product_discount_price) : parseFloat(item.product_price);

            return total + (item.quantity * usePrice);
        }, 0);

        return numberFormatting(total);
    }

    // Toast
    const [toastContent, setToastContent] = useState({
        title: '',
        content: {
            name: '',                
            text: ``
        },
        status: null,
        show: false
    });

    const closeToast = () => {
        setToastContent({
            title: '',
            content: {
                name: '',                
                text: ``
            },
            status: null,
            show: false
        });
    }

    const removeProductCart = (productVariantId, productVariantName, photoPath) => {
        const ownerCode = 'guest';

        removeFromCart(ownerCode, productVariantId);
        // setCartCounter(props.cartCount);

        setToastContent({
            title: 'Removed from cart!',
            content: {
                name: productVariantName,                
                text: `has been removed from cart!`
            },
            status: 1,
            show: true,
            image: photoPath
        });

        // handleClose();
    }

    return (
        <>
            <Offcanvas className="shopping-cart-container" placement='end' show={show} onHide={handleClose}>
                <CustomToast show={toastContent.show} 
                    onClose={closeToast} 
                    title={toastContent.title} 
                    status={toastContent.status}
                    customClass="remove-cart-toast"
            >
                <div className="image">
                    <img src={toastContent.image} alt="" />
                </div>
                <div className="content">
                    <span className='highlighted'>
                        { toastContent.content.name }
                    </span> { toastContent.content.text }
                </div>
                </CustomToast>
                {
                    data.length > 0 ? (
                        <Offcanvas.Header className='shopping-cart-header' closeButton>
                            <Offcanvas.Title className="shopping-cart-title">
                                Keranjang
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                    ) : (
                        ''
                    )
                }

                <Offcanvas.Body className='shopping-cart-body'>
                    {
                        data.length > 0 ? (
                            <>
                                <div className="shopping-cart-list">
                                    {
                                        data.map( (v, k) => {
                                            const photoPath = v.photo_path ? (`${process.env.REACT_APP_API_PRODUCT_STORAGE}${v.product_code}/${v.photo_path}`) : defaultImage;

                                            return (
                                                <div key={k} className="shopping-cart-item">
                                                    {/* 
                                                        Left - Photo
                                                        Right - Data
                                                    */}
                                                    <div className="shopping-cart-item-photo">
                                                        <img src={photoPath} alt="" />
                                                    </div>
                                                    <div className="shopping-cart-item-content">
                                                        <div className='product-content-item product-name'>{ v.variant_name } - <span className='size-name'>{ `${v.size_name} (${v.size_category_name})` }</span> </div>
                                                            {
                                                                v.product_discount_price > 0 ? (
                                                                    <div className='product-content-item product-desc'>
                                                                        { v.quantity } x <span className='product-price discounted'> { numberFormatting(v.product_price) } </span> 
                                                                        <span className='product-price'>
                                                                            { numberFormatting(v.product_discount_price) }
                                                                        </span>
                                                                    </div>
                                                                )
                                                                : (
                                                                    <div className='product-content-item product-desc'>
                                                                        { v.quantity } x <span className='product-price'> { numberFormatting(v.product_price) } </span> 
                                                                    </div>
                                                                )
                                                            }
                                                    </div>
                                                    <div className="shopping-cart-remove-btn-container">
                                                        <button className="btn-remove-cart-item" onClick={ () => removeProductCart(v.id, v.variant_name, photoPath) }>
                                                            <FontAwesomeIcon icon={['fas', 'trash']} />
                                                        </button>
                                                    </div>
                                                </div>
                                            );
                                        } )
                                    }
                                </div>
                                <div className="checkout-container">                    
                                    <div className="total-price-container">
                                        <div className="desc-text">
                                            Total: 
                                        </div>
                                        <div className="total-price">
                                            { totalPrice(data) }
                                        </div>
                                    </div>
                                    <div className="btn-container">
                                        <SecondaryButton size="sm" customClass="btn-view-cart" onClick={ () => viewCart() }>
                                            Lihat Keranjang
                                        </SecondaryButton>
                                        <PrimaryButton size="sm" customClass='btn-checkout' onClick={() => checkout()}>
                                            Checkout <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                                        </PrimaryButton>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <EmptyCart />
                        )
                    }
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart);