import api from './API';

const basePath = 'bank';
const pathList = {
    all: `/`
};

for(let key in pathList) {
    pathList[key] = basePath + pathList[key];
}

const bankList = async () => {
    try {
        const apiPath = pathList.all;
        const bank = await api.getCall(apiPath);

        return bank;
    } catch(e) {
        console.log(e.toString());
    }
};

const bankAPI = {
    bankList
}

export default bankAPI;