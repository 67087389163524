import Init from '../Init/Init';
import { isMobile } from 'react-device-detect';

const ContactInfo = () => {
    const { iconFolder, imageFolder } = Init.config;

    const contactData = {
        'maps'   : {
            link: 'https://maps.app.goo.gl/vgv9bHckJ54NHrrq8'
        },
        'address': "Komplek Daan Mogot Baru Jalan Jimbaran (Ruko) Blok LA-12A No.11, Kalideres, RT.8/RW.12, Kalideres, Jakarta Barat, Kota Jakarta Barat, Daerah Khusus Ibukota Jakarta 11840",
        'phoneno': '+6283871659555',
        'email': 'denimhouse@gmail.com',
        'connect': [
            {
                name: "denimhouseidn",
                link: "https://www.instagram.com/denimhouseidn/",
                show: true,
                faIcon: ['fab', 'instagram']
            },
            {
                name: "+6283871659555",
                link: '',
                show: true,
                faIcon: ['fab', 'whatsapp']
            },
            {
                name: "denimhouse.id@gmail.com",
                link: "mailto:denimhouse.id@gmail.com",
                show: true,
                faIcon: ['fas', 'envelope-open']
            },
        ],
        'tagline': 'House of Denim',
        'bankAccount': {
            "bank": "BCA",
            'bankAccountNo': '123456789',
            'bankAccountName': 'Denim House ID',
            'bankAccountQr': `${imageFolder}qris.jpg` 
        }
    };

    const waLink = isMobile ? `https://wa.me/${contactData.phoneno}/?[queryParam]` : `https://web.whatsapp.com/send?phone=${contactData.phoneno}&[queryParam]`;

    contactData.connect[1].link = waLink.replace(
        '[queryParam]', 
        `text=Halo Denim House, saya ingin menanyakan produk`
    );

    return contactData;
}

export default ContactInfo;