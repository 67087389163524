import React from 'react';
import { Modal } from 'react-bootstrap';
import SizeChart from '../../../Data/SizeChart';

import Init from '../../../Init/Init';

import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";

import './SizeChartModal.scss';

const SizeChartModal = (props) => {
    /**
     * Initiate variables
     */
    const { show, onHide } = props;

    const onClose = () => {
        onHide();
    }

    const chart = SizeChart();

    /**
     * Carousels
     */
    let carouselConfig = Init.config.carousel;
    /**
     * Make sure to customize config before storing it to state 
     * if necessary.
     * 
     * E.g: 
     * let carouselConfig = Init.config.carousel
     * carouselConfig.swipeable = false?
     */

    carouselConfig.containerClass = "chart-carousel-container";
    carouselConfig.itemClass = "chart-holder";
    carouselConfig.showDots = false;
    carouselConfig.infinite = false;
    carouselConfig.responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1200 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1200, min: 900 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobileTablet: {
            breakpoint: { max: 900, min: 650 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 650, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    }


    return (
        <Modal className="size-chart-modal" show={show} onHide={() => onClose()} backdropClassName='size-chart-backdrop'>
            <Modal.Header closeButton>
                <Modal.Title> Size Chart </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Carousel 
                    swipeable={carouselConfig.swipeable}
                    draggable={carouselConfig.draggable}
                    showDots={carouselConfig.showDots}
                    responsive={carouselConfig.responsive}
                    ssr={carouselConfig.ssr} // means to render carousel on server-side.
                    infinite={carouselConfig.infinite}
                    autoPlay={carouselConfig.autoPlay}
                    autoPlaySpeed={carouselConfig.autoPlaySpeed}
                    keyBoardControl={carouselConfig.keyBoardControl}
                    transitionDuration={carouselConfig.transitionDuration}
                    removeArrowOnDeviceType={carouselConfig.removeArrowOnDeviceType}
                    deviceType={carouselConfig.deviceType}
                    containerClass={carouselConfig.containerClass}
                    dotListClass={carouselConfig.dotListClass}
                    itemClass={carouselConfig.itemClass}
                    centerMode={carouselConfig.centerMode}>
                    {
                        chart.map( (item, index) => {
                            return <img key={ index } alt={`Size Chart ${ index }`} src={ item } />
                        })
                    }
                </Carousel>
            </Modal.Body>
        </Modal>
    );
}

export default SizeChartModal;