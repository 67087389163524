import React, {Component} from 'react';
import './CategoriesPage.scss';

import Init from '../../Init/Init';
import CategoryOptions from '../../Component/PagesComponent/CategoryOptions/CategoryOptions';
import CatalogHeader from '../../Component/PagesComponent/CatalogHeader/CatalogHeader';
import ProductCatalog from '../../Component/PagesComponent/ProductCatalog/ProductCatalog';

import productAPI from '../../Data/Product';
import NavigationContext from '../../Context/NavigationContext';
class CategoriesPage extends Component {
    constructor(props) {
        super(props);

        const { catalogViewType } = Init.constants.flags;

        this.state = {
            searchParam: '',
            searchType: 0,
            showSidebar: false,
            currentPath: window.location.pathname,
            currentSearch: window.location.search,
            viewType: catalogViewType.grid, // Grid or List
            sortBy: '',
        }

        this.config = {
            ...Init.config,
            FontAwesomeIcon: Init.FontAwesomeIcon
        }
    }

    static contextType = NavigationContext;

    componentDidMount() {
        // console.log("--- Mounting component ---");
        this.readUrl();

        window.addEventListener('resize', function(event){});
    }

    readUrl() {
        // console.log("--- Change search type ---");

        let state = this.state;
        let context = this.context;

        let searchValue = '';
        let searchType = 0;

        if(context.hasOwnProperty('params') && context.params.hasOwnProperty('id')) {
            searchValue = context.params.id;
            searchType = productAPI.enum.fetchType.group;
        } else if(context.hasOwnProperty('queryParams')) {
            if(context.queryParams.get('s') !== null && context.queryParams.get('s') !== '') {
                searchValue = context.queryParams.get('s');
                searchType = productAPI.enum.fetchType.search;
            } else if(context.queryParams.get('strfrnt') !== null && context.queryParams.get('strfrnt') !== '') {
                searchValue = context.queryParams.get('strfrnt');
                searchType = productAPI.enum.fetchType.storefront;
            } else if(context.queryParams.get('b')) {
                searchValue = context.queryParams.get('b');
                searchType = productAPI.enum.fetchType.brand;
            } else if(context.queryParams.get('p')) {
                searchValue = context.queryParams.get('p');
                searchType = productAPI.enum.fetchType.category;
            }
        } else {
            searchValue = 0;
            searchType = productAPI.enum.fetchType.category;
        }
        
        if(state.searchParam !== searchValue || state.searchType !== searchType) {
            state = {
                ...state,
                searchParam: searchValue,
                searchType,
                currentPath: window.location.pathname,
                currentSearch: window.location.search
            };
    
            this.setState(state);
        }

    }

    componentDidUpdate() {
        if(
            window.location.pathname !== this.state.currentPath
            ||
            window.location.search !== this.state.currentSearch
        ) {
            this.readUrl();
        }
    }
    
    componentWillUnmount() {
        // console.log("--- Unmounting component ---");

        this.setState = (state,callback)=>{
            return;
        };

        window.removeEventListener('resize', () => {});
    }

    selectCategory(id, fetchType) {
        console.log("--- Selecting category ---");

        let state = this.state;
        state.searchParam = id;
        state.searchType = fetchType;
        state.showSidebar = false;

        this.setState(state);
    }

    showSidebar() {
        console.log("--- Show Sidebar ---");

        let state  = this.state;
        state.showSidebar = true;

        this.setState(state);
    }

    hideSidebar() {
        console.log("--- Hide Sidebar ---");

        let state  = this.state;
        state.showSidebar = false;

        this.setState(state);
    }

    changeView(flag) {
        console.log("--- Change View Type ---");

        this.setState({
            ...this.state,
            viewType: flag
        });
    }

    render() {
        const { FontAwesomeIcon } = this.config;

        return (
            <div className="categories-page">
                {/* 
                    Two Big Parts
                    1. Category Options (Contains Categories with each sub categories)
                    2. Option Result ( Product Catalog )
                */}
                <CategoryOptions onClose={() => this.hideSidebar()} show={this.state.showSidebar} selectCategory={(id, fetchType) => this.selectCategory(id, fetchType)} />
                <button className="sidebar-toggler" onClick={() => this.showSidebar()}>
                    <FontAwesomeIcon icon={['fas', 'bars']} /> Show Sidebar
                </button>
                <div className="catalog-container">
                    <CatalogHeader 
                        viewType={this.state.viewType}
                        sortBy={this.state.sortBy}
                        onViewChange={(viewType) => this.changeView(viewType)}
                        onSortChange={(sortBy) => this.changeSort(sortBy)} 
                    />
                    <ProductCatalog 
                        viewType={ this.state.viewType }
                        selectCategory={(id, fetchType) => this.selectCategory(id, fetchType)} 
                        param={this.state.searchParam} 
                        fetchType={this.state.searchType} 
                    />
                </div>
            </div>
        );
    }
}

export default CategoriesPage;