import api from './API';

const basePath = 'region';
const pathList = {
    province: `/province`,
    regency: `/regency/:param`,
    district: `/district/:param`,
    village: `/village/:param`
};

for(let key in pathList) {
    pathList[key] = basePath + pathList[key];
}

const provinceList = async () => {
    try {
        const apiPath = pathList.province;
        const province = await api.getCall(apiPath);

        return province;
    } catch(e) {
        console.log(e.toString());
    }
};

const regencyList = async (id) => {
    try {
        const apiPath = pathList.regency;
        const regency = await api.getCall(apiPath, id);

        return regency;
    } catch(e) {
        console.log(e.toString());
    }
};

const districtList = async (id) => {
    try {
        const apiPath = pathList.district;
        const district = await api.getCall(apiPath, id);

        return district;
    } catch(e) {
        console.log(e.toString());
    }
};

const villageList = async (id) => {
    try {
        const apiPath = pathList.village;
        const village = await api.getCall(apiPath, id);

        return village;
    } catch(e) {
        console.log(e.toString());
    }
};

const regionAPI = {
    provinceList,
    regencyList,
    districtList,
    villageList
};

export default regionAPI;