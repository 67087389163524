import productAPI from '../../Data/Product';
import * as actionTypes from './brand-types';

const getCategories = () => {
    return async dispatch => {
        const response = await productAPI.categoryList();
    
        if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
            dispatch(getCategoriesSuccess(response.data)); 
        }
    }
}

const getCategoriesSuccess = (data) => {
    return {
        type: actionTypes.CATEGORY_LIST,
        payload: {
            data
        }
    };
}

const brandActions = {
    getCategories
}

export default brandActions;