import * as actionTypes from './faq-types';

const INITIAL_STATE = {
    FAQList: [],
    isLoaded: false
}

const FAQReducer = (state = INITIAL_STATE, action) => {
    let { FAQList, isLoaded } = state;
    
    switch(action.type) {
        case actionTypes.FAQ_LIST:
            FAQList = action.payload.data.map( (item, index) => {
                item.open = false;

                return item;
            } );
            isLoaded = FAQList.length > 0;

            return {
                ...state,
                FAQList,
                isLoaded
            };
        default: 
            return state;
    }
};

export default FAQReducer;