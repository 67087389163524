import React from 'react';

import PrimaryButton from '../PrimaryButton/PrimaryButton';
import LoadingPage from '../LoadingPage/LoadingPage';

import './EmptyCart.scss';

function EmptyCart({ isLoading }) {
    const openCatalog = () => {
        window.location.href = '/category/1';
    }
    
    return (
        <div className="shopping-cart-empty">
            {
                !isLoading ? (
                    <>
                        <span className='empty-cart-text'>
                            It seems you haven't decided which to buy...
                        </span>
                        <PrimaryButton size="sm" onClick={ () => openCatalog() }>
                            Return to category
                        </PrimaryButton>
                    </>
                ) : (
                    <LoadingPage />
                )
            }
        </div>
    );
}

export default EmptyCart;