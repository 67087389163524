import React, { useState, useEffect } from 'react';

import './PhotoCarousel.scss';

import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";

import Init from '../../../Init/Init';

const PhotoCarousel = (props) => {
    const imagePath = Init.config.imageFolder;
    const defaultImage = `${imagePath}placeholder.jpeg`;
    
    const [ photos, setPhotos ] = useState([]);
    const [ activeIndex, setActiveIndex ] = useState(0);

    const [ hideIndex, setHideIndex ] = useState(false);

    useEffect( () => {
        setPhotos( (prev) => {
            return props.photos.length > 0 ? props.photos : [ defaultImage ];
        } );
    }, [ props.photos ]);

    useEffect( () => {
        if( props.hideIndex !== null ) {
            setHideIndex(props.hideIndex);
        }
    }, [ props.hideIndex ]);

    /**
     * Carousels
     */
     let carouselConfig = Init.config.carousel;
     /**
      * Make sure to customize config before storing it to state 
      * if necessary.
      * 
      * E.g: 
      * let carouselConfig = Init.config.carousel
      * carouselConfig.swipeable = false?
      */
 
     carouselConfig.containerClass = "photo-carousel-container";
     carouselConfig.itemClass = "photo-carousel-holder";
     carouselConfig.showDots = false;
     carouselConfig.infinite = true;
     carouselConfig.responsive = {
         desktop: {
             breakpoint: { max: 3000, min: 1200 },
             items: 4,
             slidesToSlide: 1 // optional, default to 1.
         },
         tablet: {
             breakpoint: { max: 1200, min: 900 },
             items: 3,
             slidesToSlide: 1 // optional, default to 1.
         },
         mobileTablet: {
             breakpoint: { max: 900, min: 650 },
             items: 2,
             slidesToSlide: 1 // optional, default to 1.
         },
         mobile: {
             breakpoint: { max: 650, min: 0 },
             items: 1,
             slidesToSlide: 1 // optional, default to 1.
         }
     }

    return (
        <div className="photo-container">
            <div className="photo-main-container">
                <img src={photos[activeIndex]} alt={`Displaying products`} />
            </div>
            {
                hideIndex ? '' : (
                    <div className="photo-carousel-wrapper">
                        <Carousel 
                            swipeable={carouselConfig.swipeable}
                            draggable={carouselConfig.draggable}
                            showDots={carouselConfig.showDots}
                            responsive={carouselConfig.responsive}
                            ssr={carouselConfig.ssr} // means to render carousel on server-side.
                            infinite={carouselConfig.infinite}
                            autoPlay={carouselConfig.autoPlay}
                            autoPlaySpeed={carouselConfig.autoPlaySpeed}
                            customButtonGroup={''}
                            keyBoardControl={carouselConfig.keyBoardControl}
                            transitionDuration={carouselConfig.transitionDuration}
                            removeArrowOnDeviceType={carouselConfig.removeArrowOnDeviceType}
                            deviceType={carouselConfig.deviceType}
                            containerClass={carouselConfig.containerClass}
                            dotListClass={carouselConfig.dotListClass}
                            itemClass={carouselConfig.itemClass}
                            centerMode={carouselConfig.centerMode}>
                            {
                                photos !== null && photos.length > 0 ? (
                                    photos.map( (item, index) => {
                                        const photoPath = item ? item : defaultImage;
        
                                        return (
                                            <img key={index} src={photoPath} alt={`Product ${index}`} onClick={ () => setActiveIndex(index) } />
                                        )
                                    } )
                                ) : (
                                    <img src={defaultImage} alt={`Default`} />
                                )
                            }
                        </Carousel>
                    </div>
                )
            }
        </div>
    );
}

export default PhotoCarousel;