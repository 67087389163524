import React, { useRef, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Init from '../../../Init/Init';

import "react-multi-carousel/lib/styles.css";

import './DeliveryServiceModal.scss';
import shippingAPI from '../../../Data/Shipping';
import PrimaryButton from '../../Utilities/PrimaryButton/PrimaryButton';
import LoadingPage from '../../Utilities/LoadingPage/LoadingPage';

import UtilityFunction from '../../../Util/Util';

const { numberFormatting, objectIsEmpty } = UtilityFunction;

const DeliveryServiceModal = (props) => {
    /**
     * Initiate variables
     */
    const { show, onHide, destinationId, save, totalQty } = props;
    const [ courierList, setCourierList ] = useState([]);
    const [ courierCode, setCourierCode ] = useState('');
    const [ courierServiceCode, setCourierServiceCode ] = useState('');
    const [ rateInfo, setRateInfo ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isDisabled, setIsDisabled ] = useState(true);
    const [ onProcess, setOnProcess ] = useState(false);

    const onClose = () => {
        onHide();
    };

    useEffect( () => {
        if( isLoading ) {
            setCourierServiceCode('');
        }
    }, [isLoading]);

    useEffect( () => {
        if( Object.values(props.deliveryService).length > 0 ) {
            console.log(props.deliveryService);
            setCourierList(Object.values(props.deliveryService));
        }
    }, [props.deliveryService]);

    useEffect( () => {
        setIsLoading(false);
    }, [rateInfo]);

    useEffect( () => {
        if( courierCode === '' ) return ;
        setIsLoading(true);

        setRateInfo({
            name: props.deliveryService[courierCode].courier_name,
            costs: Object.values(props.deliveryService[courierCode].services)
        });
    }, [courierCode]);

    useEffect( () => {
        setIsDisabled( courierCode === '' || courierServiceCode === '' );
    }, [courierCode, courierServiceCode]);

    const saveDeliveryData = () => {
        if(isDisabled) return false;

        save(courierCode, courierServiceCode);
        setOnProcess(true);
    }

    return (
        <Modal className="delivery-service-modal" show={show} onHide={() => onClose()} backdropClassName='delivery-service-backdrop'>
            <Modal.Header closeButton>
                <Modal.Title> Choose delivery courier </Modal.Title>
            </Modal.Header>
            <Modal.Body className='delivery-service-container'>
                <div className="delivery-service-choices-container">
                    {
                        courierList.map( (item, index) => {
                            return (
                                <div key={ index } className="delivery-service-choice-container form-check">
                                    <input type="radio" className="form-check-input" id={ `delivery-service-${item.courier_code}` } name="delivery-service-code" onClick={ () => setCourierCode(item.courier_code) } value={ item.courier_code } /> 
                                    <label className='form-check-label' htmlFor={ `delivery-service-${item.courier_code}` }>
                                        { item.courier_name }
                                    </label>
                                </div>
                            )
                        } )
                    }
                </div>
                <div className="delivery-service-calculation-info">
                    {
                        isLoading ? (
                            <LoadingPage />
                        ) : (
                            rateInfo !== null ? (
                                <div className="info-container">
                                    <div className="service-name">
                                        <h4>
                                            { rateInfo.name }
                                        </h4>
                                    </div>
                                    <div className="service-costs">
                                        { rateInfo.costs.map( (item, index) => {
                                            return (
                                                <div className="service-cost-item" key={index}>
                                                    <div className="service-cost-radio-btn-container form-check">
                                                        <input type="radio" className="form-check-input" id={ `service-cost-${item.courier_service_code}` } name="service-cost-code" onClick={ () => setCourierServiceCode(item.courier_service_code) } value={ item.courier_service_code } /> 
                                                        <label className='form-check-label' htmlFor={ `service-cost-${ item.courier_service_code }` }>
                                                            { item.courier_service_name }
                                                        </label>
                                                    </div>
                                                    <div className="service-cost-description">
                                                        <div className="description-item">
                                                            Estimated Time Arrival: { `${item.duration}` }
                                                        </div>
                                                        <div className="description-item">
                                                            Estimated Price: { `${ numberFormatting(item.price) }` }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        } ) }
                                    </div>
                                </div>
                            ) : ( isDisabled ? 'Choose delivery service to proceed' : 'Free Shipping Rate for this shipping address')
                        )
                    }
                </div>
                <div className="delivery-service-confirm-btn-container">
                    {
                        onProcess ? (
                            <LoadingPage />
                        ) : (
                            <PrimaryButton 
                                className='btn-confirm' 
                                size={'sm'}
                                onClick={ () => saveDeliveryData() } 
                                disabled={ isDisabled }
                            >
                                Confirm 
                            </PrimaryButton>
                        )
                    }
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default DeliveryServiceModal;