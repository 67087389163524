import React from 'react';

import { Accordion, Col } from 'react-bootstrap';
import './CollapseBox.scss';

import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';

const CollapseBox = (props) => {
    const { boxNumber } = props;

    const gaEventCategory = 'FAQ';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    const toggleQuestion = () => {
        if(!props.list.open) {
            gaEventTracker('Toggle Open Question', props.list.question);
        }

        props.onToggle();
    };

    return(
        <Accordion.Item eventKey={boxNumber} className="question-item">
            <Accordion.Header className="question-box" onClick={toggleQuestion}>
                <span className="question-title">
                    {props.list.question}
                </span>
                {/* <Init.FontAwesomeIcon icon={['fas', (props.list.open ? 'angle-up' : 'angle-down')]} /> */}
            </Accordion.Header>
            <Accordion.Body className='question-content' dangerouslySetInnerHTML={{__html: props.list.answer}}>
            </Accordion.Body>
        </Accordion.Item>

    );
}

export default CollapseBox;