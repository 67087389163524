import React, { 
    useState, 
    // useRef, 
    useEffect, 
    useContext 
} from 'react';

import Init from '../../../Init/Init';
import UtilityFunction from '../../../Util/Util';

import './ProductContent.scss';
import Counter from '../../Utilities/Counter/Counter';
import CustomToast from '../../Utilities/Toast/Toast';
import PrimaryButton from '../../Utilities/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../Utilities/SecondaryButton/SecondaryButton';

import NavigationContext from '../../../Context/NavigationContext';

import { connect } from 'react-redux';
import shoppingCartActions from '../../../Redux/Shopping/shopping-action';
import SizeChartModal from '../SizeChartModal/SizeChartModal';

import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';

const GaTracker = ({
    action,
    label
}) => {
    const gaEventCategory = 'Product Detail';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    gaEventTracker(action, label);
};

const { numberFormatting, buildWaLink } = UtilityFunction;
const { addToCart } = shoppingCartActions;

const ProductContent = (props) => {
    /**
     * Initiate variables
     */
    const { FontAwesomeIcon, constants } = Init;
    const { flags } = constants;
    const { addToCart } = props;

    const [customerCode, setCustomerCode] = useState(null);
    const [selectedSize, setSelectedSize] = useState(0);
    const [quantity, setQuantity] = useState(1);

    const { productDetail } = props;

    // const app = useContext(AppContext);
    const navigation = useContext(NavigationContext);
    const { navigate } = navigation;
    const sharedLink = `${process.env.REACT_APP_URL}product/${productDetail.product_id}/${productDetail.product_code}`;

    const shareOptions = [
        {
            icon: ['fab', 'facebook'],
            link: ''
        },
        {
            icon: ['fab', 'whatsapp'],
            link: buildWaLink(false, `${sharedLink}\n\n${productDetail.variant_name} - Dapatkan sekarang juga di Denim House Indonesia`)
        },
        {
            icon: ['fab', 'instagram'],
            link: ''
        }
    ];

    useEffect( () => {
        if(props.identity !== undefined && props.identity.hasOwnProperty('customer_code')) {
            setCustomerCode(props.identity.customer_code);
        }
    }, [props.identity]);

    useEffect( () => {
        setSelectedSize(0);
    }, [props.productDetail]);

    useEffect( () => {
        setQuantity(1);
    }, [selectedSize]);

    const selectSize = (sizeId) => {
        let sizeName = productDetail.product_quantities.hasOwnProperty(sizeId) ? productDetail.product_quantities[sizeId].size_name : '';
        let outOfStock = productDetail.product_quantities.hasOwnProperty(sizeId) && productDetail.product_quantities[sizeId].quantity <= 0;

        GaTracker({
            action: `Choose Size`,
            label: `Product ${productDetail.variant_name}. Chosen Size: ${sizeName} (${productDetail.size_category_name}). In stock: ${ outOfStock ? 'No' : 'Yes' }`
        });

        if( !outOfStock ) {
            setSelectedSize(sizeId);
        }

    }

    const changeCounter = (counter) => {
        GaTracker({
            action: `Change Quantity`,
            label: `Product ${productDetail.variant_name}. Updated Qty: ${productDetail.product_quantities[selectedSize].quantity}`
        });

        setQuantity(counter);
    }

    const addCartItem = () => {
        if(quantity > 0) {
            const cartItem = {
                variantId: productDetail.id,
                sizeId: parseInt(selectedSize),
                quantity
            };
    
            const ownerCode = customerCode !== null ? customerCode : 'guest';

            addToCart(ownerCode, cartItem);
            
            GaTracker({
                action: `Add To Cart`,
                label: `Add product ${productDetail.variant_name} size ${productDetail.product_quantities[selectedSize].size_name} (${productDetail.size_category_name}) ${productDetail.product_quantities[selectedSize].quantity}x`
            });

            const photoPath = productDetail.photos !== null ? `${productDetail.photos[0]}` : '';
            const chosenPrice = productDetail.product_quantities[selectedSize].discount_price > 0 ? numberFormatting(productDetail.product_quantities[selectedSize].discount_price) : numberFormatting(productDetail.product_quantities[selectedSize].price);
            
            setToastContent({
                title: 'Added to cart!',
                content: {
                    title: productDetail.variant_name,
                    size: `${productDetail.product_quantities[selectedSize].size_name} (${productDetail.size_category_name})`,
                    price: chosenPrice
                },
                status: 1,
                show: true,
                image: photoPath
            });
        } else {
            //
        }

    }

    // Toast
    const [toastContent, setToastContent] = useState({
        title: '',
        content: {
            title: '',
            size: '',
            price: ''
        },
        status: null,
        show: false
    });

    const closeToast = () => {
        setToastContent({
            title: '',
            content: {
                title: '',
                size: '',
                price: ''
            },
            status: null,
            show: false
        });
    };

    // Size Chart
    const [showChart, setShowChart] = useState(false);

    const showSizeChart = () => {
        GaTracker({
            action: `Open Size Chart`,
            label: `Open Size Chart for product ${productDetail.variant_name}`
        });

        setShowChart(true);
        // alert('Size chart will be displayed in modal!');
    };

    const closeSizeChart = () => {
        setShowChart(false);
    }

    const navigateToCart = () => {
        navigate('/cart');
    };

    const compareThisProduct = () => {
        // console.log(productDetail);
        navigate('/compare', {
            state: {
                productCode: productDetail.product_code
            }
        });
    }

    return (
        <>
            <CustomToast show={toastContent.show} 
                    onClose={closeToast} 
                    title={toastContent.title}  
                    status={toastContent.status}
                    customClass='add-to-cart-toast'
            >
                <div className="cart-item-image">
                    <img src={toastContent.image} alt="" />
                </div>
                <div className="cart-item-content">
                    <div className="title">
                        { toastContent.content.title }
                    </div>
                    <div className="size">
                        Size { toastContent.content.size }
                    </div>
                    <div className="price">
                        { toastContent.content.price }
                    </div>
                </div>
                <div className="cart-item-button-container">
                    <SecondaryButton size="sm" customClass="btn-close-toast" onClick={() => closeToast()}>
                        Tutup
                    </SecondaryButton>
                    <PrimaryButton size="sm" customClass="btn-check-cart" onClick={() => navigateToCart()}>
                        Cek Keranjang <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </PrimaryButton>
                </div>
            </CustomToast>

            <SizeChartModal show={ showChart } onHide={ closeSizeChart } />

            <div className="product-content">
                {/* 
                    1. Product Name
                    2. Brand / Category
                    3. Price
                    -----------------------------
                    4. Size List
                    -----------------------------
                    5. Buy Quantity
                    -----------------------------
                    6. Button "Add To Cart"
                */}
                <div className="product-content-header">
                    <h3 className='product-name'>
                        { productDetail.variant_name }
                    </h3>
                    <span className="product-category-name">
                        { productDetail.product_category_name } / { productDetail.product_name }
                    </span>
                    {
                        productDetail.product_quantities.hasOwnProperty(selectedSize) ? (
                            productDetail.product_quantities[selectedSize].hasOwnProperty('discount_price') && productDetail.product_quantities[selectedSize].discount_price > 0 ?
                            (
                                <>
                                    <span className="product-price discounted">
                                        { numberFormatting(productDetail.product_quantities[selectedSize].price) }
                                    </span>
                                    <span className="product-price">
                                        { numberFormatting(productDetail.product_quantities[selectedSize].discount_price) }
                                    </span>
                                </>
                            ) : (
                                <span className="product-price">
                                    { numberFormatting(productDetail.product_quantities[selectedSize].price) }
                                </span>
                            )
                        ) : (
                            <span className="product-price">
                                Choose Size
                            </span>
                        )
                    }
                </div>
                <div className="product-size-list-container">
                    <div className="product-size-list-title">
                        Pilih Ukuran ({ productDetail.size_category_name })
                    </div>
                    <div className="product-size-list">
                        {
                            Object.keys(productDetail.product_quantities).length > 0 ? (
                                Object.keys(productDetail.product_quantities).map( (item, index) => {
                                    let value = productDetail.product_quantities[item];

                                    return (
                                        <div 
                                            key={index} 
                                            onClick={() => selectSize(item)} 
                                            className={
                                                `
                                                product-size-item 
                                                ${selectedSize === item ? 'active' : ''} 
                                                ${ value.quantity ? '' : 'disabled' }
                                                `
                                            }>
                                            { value.size_name }
                                        </div>
                                    )
                                } )
                            ) : ''
                        }
                    </div>
                </div>
                {
                    flags.allowTransaction ? (
                        <>
                            <div className="product-buy-quantity">
                                <Counter size="sm" onModify={(counter) => changeCounter(counter)} disabled={!selectedSize} baseValue={quantity} />
                            </div>
                            <div className="product-btn-container">
                                {/* Button Add To Cart */}
                                {/* <div className="btn-socmed-container">
                                    <span target="_blank" onClick={ () => showSizeChart() } className="share-icon">
                                        Click here to see size chart comparison
                                    </span>              
                                </div> */}
                                <PrimaryButton size="sm" customClass={`btn-add-to-cart`} disabled={ !selectedSize || !quantity } onClick={ () => addCartItem() }>
                                    <FontAwesomeIcon icon={['fas', 'shopping-cart']} /> Add to Cart <FontAwesomeIcon className='add-to-cart-icon' icon={['fas', 'arrow-right']} />
                                </PrimaryButton>
                                {/* <div className="btn-socmed-container">                    
                                    <a target="_blank" href={buildWaLink(false, `${sharedLink}\n\n${productDetail.variant_name} - Halo DenimHouse, apakah ada size lain untuk produk ini?`)} className="share-icon">
                                        Other size please contact us via <FontAwesomeIcon icon={['fab', 'whatsapp']} />
                                    </a>
                                </div> */}
                                {/* <div className="btn-socmed-container">
                                    <button className="share-icon" onClick={ () => compareThisProduct() }>
                                        Compare this product
                                    </button>
                                </div> */}
                            </div>
                        </>
                    ) : ''
                }
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        identity: state.auth.identity
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        addToCart: (ownerCode, cartItem) => dispatch(addToCart(ownerCode, cartItem))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductContent);