import Init from '../Init/Init';
import { isMobile } from 'react-device-detect';
import api from './API';

const basePath = 'linky';
const pathList = {
    all: `/`
};

for(let key in pathList) {
    pathList[key] = basePath + pathList[key];
}

const generateData = () => {
    const { iconFolder, imageFolder } = Init.config;

    const linktreeData = [
        {
            icon: 'website.svg',
            link: 'https://rednavi.co.id/',
            caption: 'Our Website is Live! 🔥'
        },
        {
            icon: 'tokopedia.svg',
            link: 'https://www.tokopedia.com/rednavisneakers?source=universe&st=product',
            caption: 'Tokopedia'
        },
        {
            icon: 'shopee.svg',
            link: 'https://shopee.co.id/rednavisneakers',
            caption: 'Shopee'
        },
        {
            icon: 'blibli.svg',
            link: 'https://www.blibli.com/merchant/rednavi/REI-70136',
            caption: 'Blibli'
        },
        {
            icon: 'whatsapp.svg',
            link: '',
            caption: 'CS WA 1 - Online Store'
        },
        {
            icon: 'whatsapp.svg',
            link: '',
            caption: 'CS WA 2 - Offline Store'
        }
    ];

    const offlineAdmin = '+6281218918925';
    const onlineAdmin = '+6289630608853';
    const waLink = isMobile ? `https://wa.me/[phoneNo]/?[queryParam]` : `https://web.whatsapp.com/send?phone=[phoneNo]&[queryParam]`;

    linktreeData[4].link = waLink.replace(
        '[queryParam]', 
        `text=Halo DenimHouse, saya ingin menanyakan produk`
    ).replace(`[phoneNo]`, onlineAdmin);
    
    linktreeData[5].link = waLink.replace(
        '[queryParam]', 
        `text=Halo DenimHouse, saya ingin menanyakan produk`
    ).replace(`[phoneNo]`, offlineAdmin);

    return linktreeData;
};

const fetchLinkyData = async () => {
    try {
        const apiPath = pathList.all;
        const linky = await api.getCall(apiPath);

        return linky;
    } catch(e) {
        console.log(e.toString());
    }
};

const linktree = async () => {
    // const response = generateData();
    const response = await fetchLinkyData();

    return response;
}

export default linktree;