import React, { 
    // useState, 
    useRef 
} from "react";
import './Counter.scss';

const Counter = (props) => {
    const { customClass, size, disabled, baseValue, onModify } = props;
    // const [baseQuantity, setBaseQuantity] = useState(baseValue);
    const quantity = useRef(baseValue);

    const toggle = {
        plus: () => {
            if(!disabled) {
                let currentQuantity = parseInt(quantity.current.value);
                quantity.current.value = currentQuantity = currentQuantity + 1;
                
                onModify(currentQuantity); 
            }
        },
        minus: () => {
            if(!disabled) {
                let currentQuantity = parseInt(quantity.current.value);
                quantity.current.value = currentQuantity -= currentQuantity > 0 ? 1 : 0;

                onModify(currentQuantity);
            }
        }
    }

    return (
        <div className={`textbox-container ${customClass} ${size}`}>
            <button className={`btn-toggle btn-minus ${disabled ? 'btn-disabled': ''}`} onClick={() => toggle.minus()}> - </button>
            <input type="text" ref={quantity} value={baseValue} className="quantity-input" readOnly />
            <button className={`btn-toggle btn-plus ${disabled ? 'btn-disabled': ''}`} onClick={() => toggle.plus()}> + </button>
        </div>
    );
}

Counter.defaultProps = {
    customClass: '',
    baseValue: 0,
    size: '',
    disabled: false,
    onModify: () => {
        //
    }
}

export default Counter;