import React, {Component} from 'react';
import './ExploreNow.scss';

import { Link } from 'react-router-dom';
import Init from '../../../Init/Init';

class ExploreNow extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const { FontAwesomeIcon } = Init;

        return (
            <div className="explore-now">
                <h2 className='explore-now-title'>
                    MORE BRANDS, MORE OPTIONS. 
                    <br />
                    EXPLORE MORE EXCITING BRAND IN REDNAVI.
                </h2>

                <Link className='btn-explore-now' to='/category'>
                    Explore Now <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                </Link>
            </div>
        );
    }
}

export default ExploreNow;