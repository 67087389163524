import * as actionTypes from './brand-types';

const INITIAL_STATE = {
    categoryList: [],
    isLoaded: false
}

const brandReducer = (state = INITIAL_STATE, action) => {
    let { categoryList, isLoaded } = state;
    
    switch(action.type) {
        case actionTypes.CATEGORY_LIST:
            categoryList = action.payload.data;
            isLoaded = categoryList.length > 0;

            return {
                ...state,
                categoryList,
                isLoaded
            };
        default: 
            return state;
    }
};

export default brandReducer;