import React from 'react';

import './RecommendedProducts.scss';

const RecommendedProducts = (props) => {
    return (
        <div id={props.id} className="recommended-products-section">

        </div>
    );
}

export default RecommendedProducts;