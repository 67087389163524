import React from 'react';
import './SearchBar.scss';

import Init from '../../../Init/Init';

const { FontAwesomeIcon } = Init;

const SearchBar = ({ refSearch, onKeywordChange, onBlurEvent, show, customClass }) => {
    const updateKeyword = (event) => {
        if( event.keyCode === 13 && event.target.value !== '' ) {
            onKeywordChange(event.target.value);
        }
    }

    return (
        <div 
            className={`search-bar ${ show ? '' : 'd-none' } ${ customClass }`}
        >
            <FontAwesomeIcon icon={['fas', 'search']} className='search-icon' />
            <input 
                type="text" 
                ref={refSearch} 
                onKeyDown={(event) => updateKeyword(event)}
                onBlur={() => onBlurEvent()} 
                className="search-box" 
                placeholder='Search...'
            />
        </div>
    );
}

export default SearchBar;