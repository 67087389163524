import * as actionTypes from './auth-types';
import authAPI from '../../Data/Auth';

const INITIAL_STATE = {
    identity: {}
}

const authReducer = (state = INITIAL_STATE, action) => {
    var identity = {};

    switch(action.type) {
        case actionTypes.IDENTITY_LOADED:
            let {
                data
            } = action.payload;

            identity = data;

            return {
                ...state,
                identity
            };
        case actionTypes.LOGOUT_SUCCESS:
            window.location.replace('/');
            return {
                ...state,
                identity
            };
        default: 
            return state;
    }
};

export default authReducer;