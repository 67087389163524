import api from './API';
import Cookie from 'universal-cookie';
import UtilityFunction from '../Util/Util';

const { responseFormat } = UtilityFunction;
const cookies = new Cookie();

const basePath = 'auth';
const pathList = {
    login: `/login`,
    register: `/register`,
    me: `/me`,
    logout: `/logout`,
    address: `/address/:param`,
    addressDelete: `/address/:param/delete`,
};

for(let key in pathList) {
    pathList[key] = basePath + pathList[key];
}

const login = async (email, password) => {
    try {
        const apiPath = pathList.login;
        const response = await api.postCall(apiPath, null, {
            email,
            password
        });

        return response;
    } catch(e) {
        console.log(e.toString());
    }
};

const register = async (email, password, confirmPassword) => {
    try {
        const apiPath = pathList.register;
        const response = await api.postCall(apiPath, null, {
            email,
            password,
            confirmPassword
        });

        return response;
    } catch(e) {
        console.log(e.toString());
    }
}

const isAuthenticated = async (argToken) => {
    try {
        const apiPath = pathList.me;
        const response = await api.getCall(apiPath, null, {}, argToken);

        console.log(response);

        return response;
    } catch(e) {
        console.log(e.toString());
    }
}

const logout = async (argToken) => {
    try {
        const apiPath = pathList.logout;
        const response = await api.getCall(apiPath, null, {}, argToken);
    
        return response;
    } catch(e) {
        console.log(e.toString());
    }
}

const addressSave = async (addressId, addressData) => {
    try {
        const apiPath = pathList.address;
        let response;
        let authToken = cookies.get('authToken');
    
        if(!authToken) return responseFormat(-1, 'Not Authenticated');
    
        console.log(addressId);

        if(addressId !== null && addressId > 0) {
            response = await api.putCall(apiPath, addressId, addressData, authToken);
        } else {
            response = await api.postCall(apiPath, '', addressData, authToken);
        }

        return response;
    } catch(e) {
        console.log(e.toString());
    }
}

const addressDelete = async (addressId) => {
    try {
        const apiPath = pathList.addressDelete;
        let response;
        let authToken = cookies.get('authToken');
    
        if(!authToken) return responseFormat(-1, 'Not Authenticated');
    
        response = await api.putCall(apiPath, addressId, {}, authToken);

        return response;
    } catch(e) {
        console.log(e.toString());
    }
}

const updateAccount = async (accountData) => {
    let authToken = cookies.get('authToken');
    if(!authToken) return responseFormat(-1, 'Not Authenticated');
    
    try {
        const apiPath = pathList.me;
        const response = api.putCall(apiPath, null, accountData, authToken);
    
        return response;
    } catch(e) {
        console.log(e.toString());
    }
}

const authAPI = {
    login,
    register,
    isAuthenticated,
    logout,
    addressSave,
    addressDelete,
    updateAccount
}

export default authAPI;