import Cookie from 'universal-cookie';

import authAPI from '../../Data/Auth';
import shoppingCartAPI from '../../Data/ShoppingCart';

import UtilityFunction from '../../Util/Util';
import * as actionTypes from './auth-types';

import shoppingCartActions from '../Shopping/shopping-action';

const { loadCart, clearCart } = shoppingCartActions;

const { objectIsEmpty, convertQueryToObj } = UtilityFunction;

const { isAuthenticated, logout } = authAPI;
const cookies = new Cookie();

const { get, save } = shoppingCartAPI.controller;
const { saveCartProducts } = shoppingCartAPI.call;

export const getAuthenticatedUser = () => {
    return dispatch => {
        dispatch(userIsLoading());
        
        const location = window.location;
        let authToken = '';

        let params = convertQueryToObj(location.search);
        if(params.hasOwnProperty('token') && params.token !== '') {
            authToken = params.token;
        } else {
            authToken = cookies.get('authToken');
        }
        
        if(authToken !== undefined && authToken !== null && authToken !== '') {
            isAuthenticated(authToken).then( async (response) => {
                if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                    if(response.data.hasOwnProperty('isLogin') && response.data.isLogin) {
                        const cartProducts = shoppingCartAPI.controller.get();
                        
                        if(!objectIsEmpty(cartProducts)) {
                            const saveCart = await saveCartProducts(authToken, cartProducts);
                            if(saveCart !== null && saveCart.hasOwnProperty('errorCode') && saveCart.errorCode === 0) {
                                shoppingCartAPI.controller.clear('guest');

                                for(let index in cartProducts) {
                                    let item = cartProducts[index];
                                    save(response.data.customer.customer_code, item);
                                }

                            }
                        }

                        cookies.set('authToken', authToken, {
                            path: '/'
                        });

                        dispatch(userIsLoaded(dispatch, {
                            data: response.data.customer
                        }));
                    } else if(response.hasOwnProperty('killSession') && response.killSession) {
                        cookies.remove('authToken');
                    } else {
                        dispatch(userIsNotLogin());
                    }
                }
            } );
            
        }
    };
};

const userIsLoading = () => {
    return {
        type: actionTypes.IDENTITY_IS_LOADING,
        payload: {}
    }
};

const userIsNotLogin = () => {
    return {
        type: actionTypes.IDENTITY_IS_NOT_AUTH,
        payload: {}
    }
};

const userIsLoaded = (dispatch, data) => {
    dispatch(clearCart());
    dispatch(loadCart(data.customer_code));

    return {
        type: actionTypes.IDENTITY_LOADED,
        payload: data
    }
};


export const userLogout = () => {
    return dispatch => {
        dispatch(isLoggingOut());

        let authToken = cookies.get('authToken');
        if(authToken !== undefined && authToken !== null && authToken !== '') {
            logout(authToken).then( (response) => {
                if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                    cookies.remove('authToken');
                    dispatch(logoutSuccess(dispatch));
                } else {
                    dispatch(logoutError());
                }
            });
        }
    };
}

const isLoggingOut = () => {
    return {
        type: actionTypes.LOGOUT_PROCESS,
        payload: {}
    }
}

const logoutSuccess = (dispatch) => {
    dispatch(loadCart());
    return {
        type: actionTypes.LOGOUT_SUCCESS,
        payload: {
            dispatch
        }
    }
}

const logoutError = () => {
    return {
        type: actionTypes.LOGOUT_ERROR,
        payload: {}
    }
}