import React, { useState, useEffect } from 'react';
import { Toast } from 'react-bootstrap';

import Init from '../../../Init/Init';

import './Toast.scss';

const CustomToast = (props) => {
    const { FontAwesomeIcon } = Init;
    
    const [ content, setContent ] = useState({
        image: '',
        title: '',
        text: '',
        status: null, // 0: Failed; 1: Success
        show: false,
        position: props.position
    });

    useEffect( () => {
        let toastData = {};

        if(props.show) {
            toastData = {
                title: props.title,
                status: props.status,
                image: props.image,
                show: true
            };
        } else {
            toastData = {
                title: '',
                status: '',
                image: '',
                show: false
            };
        }

        setContent(toastData);
    }, [props.show] );

    const close = () => {
        props.onClose();
    }

    return (
        <Toast className={`general-toast ${ props.toastClass }`} position={ content.position } show={content.show} onClose={close}>
            <Toast.Header className='general-toast-header'>
                <FontAwesomeIcon className={`${ (content.status ? 'icon-success' : 'icon-failed') }`} icon={['fas', (content.status ? 'check-circle' : 'times-circle')]} />  <span className="general-toast-title">{ content.title }</span>
                {/* <small>11 mins ago</small> */}
            </Toast.Header>
            <Toast.Body className={`general-toast-body ${props.customClass}`}>
                {props.children}
            </Toast.Body>
        </Toast>
    );
}

CustomToast.defaultProps = {
    title: '',
    status: null,
    show: false,
    toastClass: '',
    customClass: '',
    position: 'top-end'
};

export default CustomToast;