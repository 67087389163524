import api from './API';

const basePath = 'banner';
const pathList = {
    all: `/`
};

for(let key in pathList) {
    pathList[key] = basePath + pathList[key];
}

const bannerList = async () => {
    try {
        const apiPath = pathList.all;
        const banner = await api.getCall(apiPath);

        return banner;
    } catch(e) {
        console.log(e.toString());
    }
};

const bannerAPI = {
    bannerList
}

export default bannerAPI;