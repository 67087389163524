import React, { 
    useState, 
    useEffect, 
    // useRef 
}  from 'react';

import shoppingCartAPI from '../../../Data/ShoppingCart';
import UtilityFunction from '../../../Util/Util';

import { connect } from 'react-redux';
import shoppingCartActions from '../../../Redux/Shopping/shopping-action'; 

import Init from '../../../Init/Init';
import Counter from '../../../Component/Utilities/Counter/Counter';
import CustomSelect from '../../../Component/Utilities/CustomSelect/CustomSelect';
import CheckoutSection from '../../Utilities/CheckoutSection/CheckoutSection';

import NavigationContext from '../../../Context/NavigationContext';

import './ShoppingCart.scss';
import { useContext } from 'react';
import EmptyCart from '../../Utilities/EmptyCart/EmptyCart';

import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';

const GaTracker = ({
    action,
    label
}) => {
    const gaEventCategory = 'Shopping Cart';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    gaEventTracker(action, label);
};

const { call } = shoppingCartAPI;
const { adjustQty } = shoppingCartActions;
const { numberFormatting } = UtilityFunction;

const ShoppingCart = (props) => {
    const { adjustQty } = props;
    const [ data, setData ] = useState([]);
    const [ totalPrice, setTotalPrice ] = useState(0);

    const [ sizeOptions, setSizeOptions ] = useState(null);

    const { FontAwesomeIcon } = Init;
    const { imageFolder } = Init.config;
    const defaultImage = `${imageFolder}/placeholder.jpeg`;

    const navigation = useContext(NavigationContext);

    const { navigate } = navigation;

    const getCartProducts = async (cartData) => {
        let requestData = {
            id: [],
            size_id: [] 
        };

        for(let variantId in cartData) {
            let item = cartData[variantId];

            requestData.id.push(variantId);
            requestData.size_id.push(item.sizeId);
        }

        if(requestData.id.length > 0 && requestData.size_id.length > 0) {
            const response = await call.getCartProducts(requestData.id, requestData.size_id);
            
            if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                return response.data;
            } 
        }

        return null;
    };

    const bindQuantity = (cartProducts) => {
        const cartData = props.cartData;

        for(let index in cartProducts) {
            let cartItem = cartProducts[index];

            if(cartData.hasOwnProperty(cartItem.id)) {
                cartItem.quantity = cartData[cartItem.id].quantity;
            }

            cartProducts[index] = cartItem;
        }

        setData([...cartProducts]);
    };

    const countTotalPrice = (data) => {
        return data.reduce( (total, item) => {
            let usePrice = item.hasOwnProperty('product_discount_price') && item.product_discount_price > 0 ? item.product_discount_price : item.product_price;
            return total + (item.quantity * usePrice)
        }, 0);
    }

    useEffect( () => {
        bindQuantity(data);   
    }, [props.totalQty]);

    useEffect( () => {
        setTotalPrice(countTotalPrice(data));
    }, [data, props.totalQty]);

    useEffect( () => {
        ( async () => {
            const cartData = props.cartData;
            const cartProducts = await getCartProducts(cartData);

            if(cartProducts !== null) {
                const { products, sizeOption } = cartProducts;
    
                loadSizeOptions(sizeOption);
                bindQuantity(products);
                setTotalPrice(countTotalPrice(products));
            } else {
                setData([]);
                setTotalPrice(0);
            }


        } )();
    }, [props.cartData, props.cartCounter] );

    const loadSizeOptions = (sizeOption) => {
        let sizeOptionData = {};

        for(let index in sizeOption) {
            let item = sizeOption[index];

            sizeOptionData[item.variant_id] = item.sizeOptions;
        }

        setSizeOptions(sizeOptionData);
    }

    const getSizeOptions = (id) => {
        let options = [];

        if(sizeOptions.hasOwnProperty(id)) {
            for(let sizeId in sizeOptions[id]) {
                let item = sizeOptions[id][sizeId];

                options.push({
                    value: sizeId,
                    label: `${item.size_category_name} ${item.size_name}`
                });
            }
        }

        return options;
    }

    const getSizeObj = (id, size_id) => {
        let option = sizeOptions[id][size_id];

        return option !== undefined ? {
            value: `${size_id}`,
            label: `${option.size_category_name} ${option.size_name}`
        } : {};
    }

    const selectSize = (id, sizeId) => {
        let oldData = data;
        sizeId = sizeId.value;

        for(let index in oldData) {
            let item = oldData[index];

            if(id === item.id) {
                let sizeOpt = sizeOptions[id][sizeId];

                item.size_id = parseInt(sizeId);
                item.product_price = sizeOptions[id][sizeId].product_price;

                GaTracker({
                    action: `Change Size`,
                    label: `Cart Product: ${item.variant_name}; Size: ${sizeOpt.size_name}`
                });
            }

            oldData[index] = item;
        }

        setData([
            ...oldData
        ]);
    };

    const checkCounter = (variantKey, quantity) => {
        let variantId = data[variantKey].id;
        let variantName = data[variantKey].variant_name;
        const ownerCode = props.identity !== undefined && props.identity.hasOwnProperty('customer_code') ? props.identity.customer_code : 'guest';

        GaTracker({
            action: `Change Counter`,
            label: `Cart Product: ${variantName}; Qty: ${quantity}`
        });
        
        adjustQty(ownerCode, variantId, quantity);
    };

    const checkout = () => {
        GaTracker({
            action: `Click Checkout Button`,
            label: `Initiate Checkout - ${JSON.stringify(data)}`
        });

        navigate('/checkout', {
            replace: true,
            state: {
                cartData: data
            }
        });
    }

    return (
        <>
            <CheckoutSection id="section-2" totalPrice={totalPrice} checkout={ () => checkout() } />
            <div id={props.id} className="shopping-cart-section">
                {
                    data !== null &&
                    data.length > 0 &&
                    sizeOptions !== null ? (
                        <>
                            <h3 className='shopping-cart-title'>
                                <FontAwesomeIcon icon={['fas', 'shopping-cart']} /> Shopping Cart
                            </h3>
                            <table className='shopping-cart-table'>
                                <thead>
                                    <tr>
                                        {/* <th colSpan={2}> Product </th>
                                        <th> Size </th>
                                        <th> Quantity </th>
                                        <th> Price </th> */}
                                        <th colSpan={2}> Product </th>
                                        <th> Size </th>
                                        <th> Quantity </th>
                                        <th> Price </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data !== null &&
                                        data.length > 0 &&
                                        sizeOptions !== null &&
                                        data.map( (v, k) => {
                                            const photoPath = v.photo_path ? `${process.env.REACT_APP_API_PRODUCT_STORAGE}/${v.product_code}/${v.photo_path}` : defaultImage

                                            return (
                                                <tr key={k}>
                                                    <td className='product-photo'>
                                                        <img src={photoPath} alt="" />
                                                    </td>
                                                    <td className='product-name'>
                                                        {
                                                            v.variant_name
                                                        }
                                                    </td>
                                                    <td className='product-size'>
                                                        {/* {
                                                            v.size_name
                                                        } */}
                                                        <CustomSelect
                                                            id={v.id}
                                                            className="product-size-select"
                                                            baseValue={ getSizeObj(v.id, v.size_id) }
                                                            // baseValue={ v.size_id }
                                                            callback={ () => getSizeOptions(v.id) }
                                                            onSelect={ (sizeObj) => selectSize(v.id, sizeObj) }
                                                            height={32}
                                                        />
                                                    </td>
                                                    <td className='product-qty'>
                                                        <Counter size={'sm'} customClass="product-qty-counter" baseValue={v.quantity} onModify={(quantity) => checkCounter(k, quantity)} />
                                                    </td>
                                                    <td className='product-price'>
                                                        {
                                                            v.hasOwnProperty('product_discount_price') && v.product_discount_price > 0 ? (
                                                                <>
                                                                    <span className='price discounted'> { numberFormatting(v.product_price) } </span> <span className="price"> { numberFormatting(v.product_discount_price) } </span>
                                                                </>
                                                            ) : (
                                                                <span className='price'>
                                                                    {
                                                                        numberFormatting(v.product_price)
                                                                    }
                                                                </span>
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        } )
                                    }
                                </tbody>
                            </table>
                        </>
                    ) : (
                        <EmptyCart />
                    )
                }
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        identity: state.auth.identity,
        cartData: state.shop.cartProducts,
        cartCounter: state.shop.cartCounter,
        totalQty: state.shop.totalQty
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        adjustQty: (ownerCode, variantId, quantity) => dispatch(adjustQty(ownerCode, variantId, quantity))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart);