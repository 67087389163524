import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';

import PrimaryButton from '../PrimaryButton/PrimaryButton';

import Init from '../../../Init/Init';
import UtilityFunction from '../../../Util/Util';
import ContactInfo from '../../../Data/Contact';

import './CheckoutSection.scss';

import { Modal, Image } from 'react-bootstrap';

const { numberFormatting } = UtilityFunction;

const CheckoutSection = (props) => {
    const { FontAwesomeIcon, config } = Init; 
    const { bankAccount } = ContactInfo();

    const [ showQR, setShowQR ] = useState(false);
    const openQR = () => setShowQR(false);
    const closeQR = () => setShowQR(false);

    const [ onProcess, setOnProcess ] = useState(false);

    const { 
        iconFolder, 
        // imageFolder 
    } = config;
    // const checkoutBackgroundImage = `${imageFolder}/bill.png`;

    const checkout = (usePaymentGateway) => {
        props.checkout(usePaymentGateway);
        // setOnProcess(true);
    };

    return (
        <>
            <Modal show={ showQR } onHide={ closeQR }>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Transfer to { bankAccount.bankAccountName }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Image fluid={true} src={ bankAccount.bankAccountQr } />
                </Modal.Body>
            </Modal>
            <div id={props.id} className="checkout-section">
                <h2 className='checkout-section-title'>
                    Summary
                </h2>
                <div className="checkout-detail">
                    <table className='checkout-detail-table'>
                        <tbody>
                            <tr>
                                <td>Subtotal</td>
                                <td align='right'>{ numberFormatting(props.totalPrice) }</td>
                            </tr>
                            {/* <tr>
                                <td>Tax</td>
                                <td align='right'>{ numberFormatting(props.totalPrice * 5/100) }</td>
                            </tr> */}
                        </tbody>
                        <tfoot>
                            <tr className='total-row'>
                                <td>TOTAL</td>
                                <td align='right'>{ numberFormatting(
                                    props.totalPrice 
                                    // + 
                                    // (props.totalPrice * 5/100)
                                ) }</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    METODE PEMBAYARAN:
                                </td>
                            </tr>
                            <tr className='payment-method-desc'>
                                <td>
                                    <img src={`${iconFolder}/bca.svg`} alt="" />
                                </td> 
                                <td>
                                    BCA Transfer (Verifikasi Manual / Wajib mengirim bukti pembayaran)
                                    <br />
                                    <span className="bank-account" onClick={ () => openQR() }>
                                        { bankAccount.bankAccountNo } a.n. { bankAccount.bankAccountName }
                                    </span>
                                </td>
                            </tr>
                            <tr className='payment-method-desc'>
                                <td colSpan={2} style={{
                                    padding: '20px 0',
                                    textAlign: 'center'
                                }}>
                                    {
                                        !onProcess ? (
                                            <PrimaryButton size="md" customClass="checkout-button" onClick={ () => checkout(false) }>
                                                Manual Checkout <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                                            </PrimaryButton>
                                        ) : (
                                            <Spinner animation='border' />
                                        )
                                    }
                                </td>
                            </tr>
                            <tr className='payment-method-desc'>
                                <td>
                                    <img src={`${iconFolder}/xendit.png`} alt="" />
                                </td> 
                                <td>
                                    Bayar via <strong><u>Xendit</u></strong> menggunakan virtual account bank partner, pembayaran penuh kartu kredit / debit, cicilan kartu kredit, paylater, kredivo, atome, dll
                                </td>
                            </tr>
                            <tr className='payment-method-desc'>
                                <td colSpan={2} style={{
                                    padding: '20px 0',
                                    textAlign: 'center'
                                }}>
                                    {
                                        !onProcess ? (
                                            <PrimaryButton size="md" customClass="checkout-button" onClick={ () => checkout(true) }>
                                                Use Xendit PG <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                                            </PrimaryButton>
                                        ) : (
                                            <Spinner animation='border' />
                                        )
                                    }
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </>
    );
};

export default CheckoutSection;