import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { webInit } from '../../../Data/Init';

const SNI = (props) => {
    const [ title, setTitle ] = useState("Denim House");
    const [ description, setDescription ] = useState("Denim House Indonesia");
    const [ keywords, setKeywords ] = useState("denim house,nudie,studio d artisans,samurai jeans,jeans,denim");

    useEffect( () => {
        (async() => {
            const response = await webInit();

            console.log(response);

            if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                const { data } = response;

                console.log(data);

                setTitle(data.websiteTitle);
                setDescription(data.websiteDescription);
                setKeywords(data.websiteMetaKeywords);
            }
        })();
    }, []);

    return (
        <Helmet>
            <title> { title } </title>
            <meta
                name="description"
                content={ description }
            />
            <meta 
                name="keywords" 
                content={ keywords } 
            />
        </Helmet>
    );
}

export default SNI;