import React, { useContext } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Init from '../../../Init/Init';
import UtilityFunction from '../../../Util/Util';
import './ProductCard.scss';

import NavigationContext from '../../../Context/NavigationContext';
import productAPI from '../../../Data/Product';

const ProductCard = (props) => {
    /**
     * Initiate variables
     */
    const imagePath = Init.config.imageFolder;
    const defaultImage = `${imagePath}placeholder.jpeg`;

    const { config } = Init;
    const { quickLook } = config.icons;
    const { numberFormatting } = UtilityFunction;

    const { item, onPeek } = props;

    const { navigate } = useContext(NavigationContext);

    const photoPath = item.photo_path ? (`${process.env.REACT_APP_API_PRODUCT_STORAGE}${item.product_code}/${item.photo_path}`) : defaultImage;

    /**
     * Custom Functions
     */
     const quickLookModal = (productCode) => {
        onPeek(productCode);
    };

    const openProductDetail = (event, {
        productCode,
        productId
    }) => {
        const openingModal = event.target.className === 'btn-quick-look';
        const pickProduct = props.enablePick;

        console.log({
            pickProduct
        });

        if( pickProduct ) {
            // Send this product to parent
            props.onChoose(productCode);
        } else if(!openingModal) {
            navigate(`/product/${productId}/${productCode}`, {
                replace: false,
                state: {
                    productCode,
                    productId,
                    fetchType: productAPI.enum.fetchType.category
                }
            });
        }
    }

    return (
        <div className={`product-card-container`}>
            <img className="btn-quick-look" src={quickLook} alt="" onClick={() => quickLookModal(item.product_code)}/>
            <Card className="product-card"
                // as={Link} 
                // to={{
                //     pathname: `/product/${item.product_id}/${item.product_code}`,
                //     search: "",
                //     hash: "",
                //     state: { 
                //         productCode: item.product_code,
                //         productId: item.product_id,
                //         fetchType: productAPI.enum.fetchType.category
                //     }
                // }}
                onClick={(event) => openProductDetail(event, {
                    productCode: item.product_code,
                    productId: item.product_id
                })}
            >
                <img
                    className="product-card-image" 
                    src={photoPath} alt="" />
                <Card.Body className="product-card-body">
                    <Card.Title className="product-card-name">{item !== null ? item.variant_name : 'Loading...'}</Card.Title>
                    <div className="card-text product-card-desc">
                        <div className="product-card-first-row mb-2">
                            <div className="product-card-category">
                                {item.product_category_name} - { item.product_name }
                            </div>
                        </div>
                        {
                            item.hasOwnProperty('product_discount_price') && item.product_discount_price > 0 && (item.product_price != item.product_discount_price) ? (
                                <>
                                    <div className="product-card-price discounted">
                                        { numberFormatting(item.product_price) }
                                    </div>

                                    <div className="product-card-price">
                                        { numberFormatting(item.product_discount_price) }
                                    </div>
                                </>
                            ) : (
                                <div className="product-card-price">
                                    { numberFormatting(item.product_price) }
                                </div>
                            )
                        }
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

export default ProductCard;