import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';

import './CustomSelect.scss';

const CustomSelect = (props) => {
    const { clearable, searchable, disabled, rtl, onSelect, selectName, className, callback, height } = props;

    const [ options, setOptions ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(true);
    const selectRef = useRef(null);

    useEffect( () => {
        if(callback !== null) {
            (
                async() => {
                    setIsLoading(true);
                    const optionData = await callback();
                    setOptions([...optionData]);
                }
            )();
        }
    }, [props.id]);

    useEffect( () => {
        setIsLoading(false);
    }, [options]);

    useEffect( () => {
        if(selectRef.current.props.value !== undefined && selectRef.current.props.value.value !== props.baseValue.value) {
            selectRef.current.setValue(props.baseValue);
        }
    }, [props.baseValue]);

    const onChoose = (option) => {
        onSelect(option);
    }

    return (
    <>
        <Select
            className={`custom-select ${className}`}
            defaultValue={props.baseValue}
            ref={selectRef}
            // value={baseValue}
            isDisabled={disabled}
            isLoading={isLoading}
            isClearable={clearable}
            isRtl={rtl}
            isSearchable={searchable}
            name={selectName}
            options={options}
            height={height}
            onChange={ (value) => onChoose(value) }
        />
    </>
    );
}

CustomSelect.defaultProps = {
    id: 0,
    selectName: 'custom-select',
    clearable: false,
    searchable: false,
    disabled: false,
    rtl: false,
    baseValue: 1,
    className: '',
    callback: null,
    height: 35,
    onSelect: () => {
        //
    }
}

export default CustomSelect;