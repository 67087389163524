import React, {Component} from 'react';

import OrderMessage from '../../Component/PagesComponent/OrderMessage/OrderMessage';
import OrderDetail from '../../Component/PagesComponent/OrderDetail/OrderDetail';

import './OrderDetailPage.scss';
import NavigationContext from '../../Context/NavigationContext';

import useAnalyticsEventTracker from '../../GoogleAnalytics/useAnalyticsEventTracker';

const GaTracker = ({
    action,
    label
}) => {
    const gaEventCategory = 'Order Detail';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    gaEventTracker(action, label);
};

class OrderDetailPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            transactionCode: '',
            checkoutSuccess: 0
        }
    }

    static contextType = NavigationContext;

    componentDidMount() {
        const state = this.state;
        const context = this.context;

        const { navigate, location, params } = context;
        const locationState = location.state;

        if(params.hasOwnProperty('transactionCode')) {
            state.transactionCode = params.transactionCode;
            state.checkoutSuccess = locationState !== null && locationState.hasOwnProperty('success') ? locationState.success : false;

            if(state.checkoutSuccess) {
                GaTracker({
                    action: `Checkout`,
                    label: `Checkout Success with trx code ${state.transactionCode}`
                });
            }

            this.setState(state);
        } else {
            navigate('/order', {
                replace: true,
                state: {}
            });
        }

        window.addEventListener('resize', function(event){});
    }

    componentDidUpdate() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    
    componentWillUnmount() {
        this.setState = (state,callback)=>{
            return;
        };

        window.removeEventListener('resize', () => {});
    }

    render() {
        return (
            <div className='order-detail-page'>
                {
                    this.state.checkoutSuccess ? (
                        <OrderMessage />
                    ) : ''
                }
                <OrderDetail transactionCode={this.state.transactionCode} />
            </div>
        );
    }
}

export default OrderDetailPage;