import api from './API';

const basePath = 'marquee';
const pathList = {
    all: `/`
};

for(let key in pathList) {
    pathList[key] = basePath + pathList[key];
}

const marqueeList = async () => {
    try {
        const apiPath = pathList.all;
        const marquee = await api.getCall(apiPath);

        return marquee;
    } catch(e) {
        console.log(e.toString());
    }
};

const marqueeAPI = {
    marqueeList
}

export default marqueeAPI;