import React from 'react';
import Init from '../../../Init/Init';
import ContactInfo from '../../../Data/Contact';

import { Link } from 'react-router-dom';

import './WhatsappButton.scss';
import UtilityFunction from '../../../Util/Util';

const { buildWaLink } = UtilityFunction;

const WhatsappButton = (props) => {
    const { FontAwesomeIcon, config } = Init;
    const { iconFolder } = config;
    const { isFixed, customClass, show } = props;
    const info = ContactInfo();

    const waLink = buildWaLink();
    const favicon = `${iconFolder}/favicon.png`;



    return (
        <a 
            target="_blank" 
            className={`btn-pre-order ${show ? '' : 'd-none'} ${isFixed ? 'is-fixed' : ''} ${customClass}`} 
            href={waLink}
        >
            <FontAwesomeIcon className='btn-pre-order-icon' icon={['fab', 'whatsapp']} /> 
            {/* <img className='btn-pre-order-icon' src={favicon} alt="" /> <span className='btn-pre-order-text'>Konsultasi Sekarang!</span> */}
        </a>
    );
}

WhatsappButton.defaultProps = {
    isFixed: false,
    customClass: '',
    show: true
}

export default WhatsappButton;