import React, { useState, useEffect } from 'react';
import PaymentForm from '../../Component/PagesComponent/PaymentForm/PaymentForm';
import CustomToast from '../../Component/Utilities/Toast/Toast';
import Init from '../../Init/Init';

import { Link } from 'react-router-dom';

import './PaymentConfirmationPage.scss';

const PaymentConfirmationPage = (props) => {
    const [ isSuccess, setIsSuccess ] = useState(false);
    const { FontAwesomeIcon } = Init;

    const submitPaymentForm = (status, message) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        if(status) {
            showToast(true, message);
            setIsSuccess(true);
        } else {
            showToast(false, message);
        }
    }

    /**
     * Toast
     */
    const [toastContent, setToastContent] = useState({
        title: '',
        content: '',
        status: null,
        show: false
    });

    const showToast = (success = false, message) => {
        const content   = toastContent;
        content.title   = success ? 'Success' : 'Failed';
        content.content = message;
        content.status  = success;
        content.show    = true;

        setToastContent({
            ...content
        });
    }

    const closeToast = () => {
        setToastContent({
            ...toastContent,
            show: false
        });
    };

    useEffect( () => {
        if(isSuccess) {
            showToast(true, toastContent.content);
        }
    }, [isSuccess]);

    return (
        <>
            <CustomToast show={toastContent.show} 
                onClose={closeToast} 
                title={toastContent.title} 
                status={toastContent.status}
                customClass="payment-confirmation-toast"
            >
                <div className="content">
                    { toastContent.content }
                </div>
            </CustomToast>
            {
                !isSuccess ? (
                    <div className='payment-confirmation-page'>
                        <PaymentForm onSubmit={ (status, message) => submitPaymentForm(status, message) } />
                    </div>
                ) : (
                    <div className="payment-confirmation-result-page">
                        <h2 className='payment-confirmation-result-title'>
                            Payment Submission Success!
                        </h2>
            
                        <div className="payment-confirmation-result-message">
                            { toastContent.content }
                        </div>
            
                        <Link className='btn-back-home' to='/'>
                            <FontAwesomeIcon icon={['fas', 'arrow-left']} /> Kembali ke Beranda
                        </Link>
                    </div>
                )
            }
        </>
    );
}

export default PaymentConfirmationPage;