import * as actionTypes from './shopping-types';
import shoppingCartAPI from '../../Data/ShoppingCart';

const INITIAL_STATE = {
    cartProducts: {},
    cartCounter: 0,
    totalQty: 0
}

const shopReducer = (state = INITIAL_STATE, action) => {
    let { cartProducts, cartCounter, totalQty } = state;
    
    switch(action.type) {
        case actionTypes.ADD_TO_CART:
            /**
             * 1. Get items data from cart
             * 2. Check if item exists
             */
            var { ownerCode, productItem } = action.payload;

            var { variantId, sizeId } = productItem;

            if(cartProducts.hasOwnProperty(variantId)) {
                if(cartProducts.sizeId !== sizeId) {
                    cartProducts[variantId].sizeId = sizeId;
                    cartProducts[variantId].quantity = parseInt(productItem.quantity);
                } else {
                    cartProducts[variantId].quantity = parseInt(cartProducts[variantId].quantity) + parseInt(productItem.quantity);
                }
                // cartProducts[variantId].quantity += productItem.quantity;
            } else {
                cartProducts[variantId] = productItem;
                cartCounter += 1;
            }

            totalQty += parseInt(productItem.quantity);

            shoppingCartAPI.controller.save(ownerCode, productItem);

            return {
                ...state,
                cartProducts,
                cartCounter,
                totalQty
            };
        case actionTypes.REMOVE_FROM_CART:
            var { ownerCode, variantId } = action.payload;

            if(cartProducts.hasOwnProperty(variantId)) {
                delete cartProducts[variantId];
                cartCounter -= 1;
                totalQty -= 1;
            }

            shoppingCartAPI.controller.delete(ownerCode, variantId);

            return {
                ...state,
                cartProducts,
                cartCounter,
                totalQty
            };
        case actionTypes.ADJUST_QTY:
            var { ownerCode, variantId, qty } = action.payload;

            if(cartProducts.hasOwnProperty(variantId)) {
                if(qty > 0) {
                    cartProducts[variantId].quantity = parseInt(qty);
                    shoppingCartAPI.controller.save(ownerCode, cartProducts[variantId]);
                } else {
                    delete cartProducts[variantId];
                    cartCounter -= 1;
                    shoppingCartAPI.controller.delete(ownerCode, variantId);
                }
            }

            totalQty = 0;

            for(let index in cartProducts) {
                totalQty += parseInt(cartProducts[index].quantity);
            }

            return {
                ...state,
                cartProducts,
                cartCounter,
                totalQty
            };
        case actionTypes.LOAD_CART:
            cartProducts = action.payload.cartData;
            cartCounter = Object.keys(cartProducts).length;
            // console.log(cartProducts);
            // console.log({cartCounter});

            return {
                ...state,
                cartProducts,
                cartCounter
            };
        case actionTypes.CLEAR_CART:
            shoppingCartAPI.controller.clear(action.payload.ownerCode);

            return INITIAL_STATE;
        default: 
            return state;
    }
};

export default shopReducer;