import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';

import Init from '../../../Init/Init';

import './ArticleCollection.scss';
import LoadingPage from '../../Utilities/LoadingPage/LoadingPage';
import FormInput from '../../Utilities/FormInput/FormInput';

import { connect } from 'react-redux';
import articleActions from '../../../Redux/Article/article-action';

const { loadArticle } = articleActions;

const mapStateToProps = (state) => {
    return {
        article: state.article
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadArticle: (startId, limit, keyword, refresh) => dispatch(loadArticle(startId, limit, keyword, refresh))
    };
}

const ArticleCollection = (props) => {
    /**
        Two parts:
        1. List
        2. Embed Twitter
    */
    const [ article, setArticle ] = useState({
        data: [],
        count: 0
    });
    const limit = props.limit;
    const [ startId, setStartId ] = useState(null);
    const [ keyword, setKeyword ] = useState('');
    const searchRef = useRef('');
    const [ isTwitterLoaded, setIsTwitterLoaded ] = useState(false);
    const twitterAccount = 'Landackid';
    
    const { FontAwesomeIcon } = Init;

    const navigate = useNavigate();

    const gaEventCategory = 'Article';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    useEffect( () => {
        const refresh = startId === null || startId === 0;

        props.loadArticle(startId, limit, keyword, refresh);
    }, [startId]);

    useEffect( () => {
        setStartId(startId === 0 ? null : 0);
    }, [keyword]);

    useEffect(() => {
        setArticle({
            data: Object.values(props.article.data),
            count: props.article.amount
        });
    }, [props.article.data]);

    useEffect( () => {
        if(isTwitterLoaded) {
            console.log('Twitter is loaded');
        }
    }, [isTwitterLoaded]);

    const seeMoreArticle = () => {
        let articleLength = article.data.length;
        let lastIndex = articleLength - 1;
        let lastId = 0;

        if(articleLength < article.count && lastIndex >= 0) {
            lastId = article.data[lastIndex].id;
            setStartId(lastId);
        }
    };

    const searchArticle = () => {
        let inputElement = searchRef.current;
        let value = inputElement.value;
        
        setKeyword(value);
    };

    const inputChange = (name, value) => {
        // console.log(name, value);
    }

    return (
        <div className='article-collection-container'>
            <div className="article-collection">
                <div className="article-item article-search-container">
                    <FormInput 
                        attribute={{
                            label: "Cari Artikel: ",
                            ref: searchRef,
                            width: 12, // x < 12
                            type: "text",
                            required: true,
                            placeholder: 'Masukkan kata kunci'
                        }}
                        name={'keyword'} 
                        onChange={ (name, value) => inputChange(name, value) } 
                    />
                </div>
                <div className="article-item article-search-container">                        
                    <button className='search-button' onClick={() => searchArticle()}>
                        <FontAwesomeIcon icon={['fas', 'search']} /> Cari
                    </button>
                </div>
                {
                    article.data.length > 0 ? (
                        article.data.map( (v, k) => {
                            return (
                                <div key={k} className="article-item" onClick={() => navigate(`/article/${v.article_slug}`, {
                                    replace: false
                                })}>
                                    <div className="article-thumbnail">
                                        <img src={v.article_image} alt="" />
                                    </div>
                                    <div className="article-content">
                                        <h5 className='content-title'>
                                            { v.article_title }
                                        </h5>
                                        <span className="content-publisher">{ v.published_by }</span> - <span className='content-publish-time'>{ v.published_time }</span>
                                        <p className="article-text">
                                            {v.short_description}
                                        </p>
                                    </div>
                                </div>
                            )
                        } )   
                    ) : (
                        <div className="article-item empty-page">
                            <span className="search-icon">
                                <FontAwesomeIcon icon={['fas', 'search']} />
                            </span>
                            <span className="search-result">
                                Ups, artikel yang anda cari tidak ada...
                            </span>
                        </div>
                    )
                }
                {
                    article.data.length !== article.count ? (
                        <div className="article-item see-more">
                            <button onClick={() => seeMoreArticle()} className='btn-article-see-more'>
                                Lihat Lebih Banyak <FontAwesomeIcon icon={['fas', 'arrow-down']} />
                            </button>
                        </div>
                    ) : ''
                }
            </div>
        </div>
    );
}

ArticleCollection.defaultProps = {
    article: {},
    limit: 5
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleCollection);