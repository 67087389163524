import React, { useState, useEffect } from 'react';

import productAPI from '../../../Data/Product';

import './ProductDetail.scss';

import PhotoCarousel from '../PhotoCarousel/PhotoCarousel';
import ProductContent from '../ProductContent/ProductContent';
import LoadingPage from '../../Utilities/LoadingPage/LoadingPage';

const ProductDetail = (props) => {
    const [productDetail, setProductDetail] = useState(false);
    const productCode = props.productCode;

    const { isModal } = props; 

    useEffect( () => {
        if(productCode) {
            (async () => {
                const response = await productAPI.productDetail(productCode);

                if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                    for(let i in response.data.photos) {
                        response.data.photos[i] = `${process.env.REACT_APP_API_PRODUCT_STORAGE}${response.data.product_code}/${response.data.photos[i]}`
                    }

                    setProductDetail(response.data);
                }
            })();
        }
    }, [productCode]);

    return productDetail.hasOwnProperty('id') ? (
        <div className={`product-detail ${isModal ? '' : 'full-page'}`}>
            <PhotoCarousel photos={productDetail.photos} />
            <ProductContent productDetail={productDetail} />
            <div itemScope itemType="http://schema.org/Product">
                <meta itemProp="brand" content={productDetail.product_category_name} />
                <meta itemProp="name" content={`${productDetail.product_name} - ${productDetail.variant_name}`} />
                <meta itemProp="description" content={ `${productDetail.variant_name}` } />
                <meta itemProp="productID" content={productCode} />
                <meta itemProp="url" content={window.location.href} />
                <meta itemProp="image" content={productDetail.photos[0]} />
                {/* <div itemProp="value" itemScope itemType="http://schema.org/PropertyValue">
                    <span itemProp="propertyID" content={productDetail}></span>
                    <meta itemProp="value" content="fb_tshirts" />
                </div> */}
                <div itemProp="offers" itemScope itemType="http://schema.org/Offer">
                    <link itemProp="availability" href="http://schema.org/InStock" />
                    <link itemProp="itemCondition" href="http://schema.org/NewCondition" />
                    <meta itemProp="price" content={ productDetail.product_quantities[Object.keys(productDetail.product_quantities)[0]].price } />
                    <meta itemProp="priceCurrency" content="IDR" />
                </div>
            </div>
        </div>
    ) : (
        <LoadingPage />
    );
}

ProductDetail.defaultProps = {
    isModal: true
}

export default ProductDetail;

 /**
     * [
     *      {
     *          "id":21,
     *          "product_id":2,
     *          "size_category_id":1,
     *          "product_code":"553558-062",
     *          "variant_name":"Air Jordan 1 Low Black Grey Pink",
     *          "status":1,"condition":"BNIB",
     *          "notes":null,
     *          "created_at":"2022-11-18T15:33:42.000Z",
     *          "updated_at":"2022-11-18T15:33:42.000Z",
     *          "photos":["553558-062_1.png","553558-062_2.png"]
     *          "product_name":"Air Jordan 1 Low",
     *          "product_category_name":"Nike",
     *          "product_quantities":{
     *              "6":{
     *                  "price":3300000,
     *                  "quantity":20,
     *                  "size_name":"9"
     *              },
     *              "21":{
     *                  "price":3000000,
     *                  "quantity":15,
     *                  "size_name":"7.5"
     *              }
     *          },
     *          "size_category_name":"US"
     *      }
     * ]
     */