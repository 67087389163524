import React, { useState, useEffect } from "react";

import productAPI from "../../../Data/Product";
import CategoryOption from "./CategoryOption/CategoryOption";

import './CategoryOptions.scss';
import LoadingPage from "../../Utilities/LoadingPage/LoadingPage";
import Init from "../../../Init/Init";

import { connect } from "react-redux";

import groupActions from '../../../Redux/Group/group-action';
import brandActions from '../../../Redux/Brand/brand-action';
import GroupOption from "./GroupOption/GroupOption";

const { getCategories } = brandActions;
const { getGroup } = groupActions;

const mapStateToProps = (state) => {
    return {
        group: state.group.groupList,
        groupLoaded: state.group.isLoaded,
        brand: state.brand.categoryList,
        brandLoaded: state.brand.isLoaded
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCategories: () => dispatch(getCategories()),
        getGroup: () => dispatch(getGroup())
    }
};

const CategoryOptions = (props) => {
    const { FontAwesomeIcon } = Init;
    const [ categories, setCategories ] = useState([]);
    const [ groups, setGroups ] = useState([]);
    const { selectCategory, onClose } = props;
    const [ show, setShow ] = useState(false);

    useEffect( () => {
        if(!props.brandLoaded) {
            props.getCategories();
        }

        if(!props.groupLoaded) {
            props.getGroup();
        }
    }, []);

    useEffect(() => {
        setCategories(props.brand);
    }, [props.brand]);

    useEffect(() => {
        setGroups(props.group);
    }, [props.group]);

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    return (
        <div className={`category-options ${ show ? 'show' : '' }`}>
            <div className="category-options-container">
                <h4 className="category-title">
                    Semua Kategori

                    <button onClick={() => onClose()} className="btn-close-sidebar">
                        <FontAwesomeIcon icon={['fas', 'times']} />
                    </button>
                </h4>
                {/* 
                    Collapse Dropdown, each category has their own sub-categories
                    (1 Product Category -> Many Products)
                */}
                {/* <div className="category-option-container">
                    <h5 className='filter-title'>
                        General                     
                    </h5>
                    {
                        groups.length > 0 ? (
                            groups.map( (item, index) => {
                                return (
                                    <GroupOption key={index} item={item} onChoose={(id, fetchType) => selectCategory(id, fetchType)} />
                                );
                            } )
                        ) : (
                            <LoadingPage />
                        )
                    }
                </div> */}
                <div className="category-option-container">
                    <h5 className='filter-title'>
                        Brands                     
                    </h5>
                    {
                        categories.length > 0 ? (
                            categories.map( (item, index) => {
                                return (
                                    <CategoryOption key={index} item={item} onChoose={(id, fetchType) => selectCategory(id, fetchType)} />
                                );
                            } )
                        ) : (
                            <LoadingPage />
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryOptions);