import React, {Component, createRef} from 'react';

import './LoginPage.scss';

import Init from '../../Init/Init';
import UtilityFunction from '../../Util/Util';
import SecondaryButton from '../../Component/Utilities/SecondaryButton/SecondaryButton';

import NavigationContext from '../../Context/NavigationContext';
import { Row } from 'react-bootstrap';
import FormInput from '../../Component/Utilities/FormInput/FormInput';
import PrimaryButton from '../../Component/Utilities/PrimaryButton/PrimaryButton';
import authAPI from '../../Data/Auth';


const { getGoogleOauthURL } = UtilityFunction;

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginForm: [
                {
                    name: 'email',
                    attribute: {
                        label: "Email",
                        ref: createRef(''),
                        value: '',
                        width: 12, // x < 12
                        type: "text",
                        required: true,
                        placeholder: 'Enter email'
                    }
                },
                {
                    name: 'password',
                    attribute: {
                        label: "Password",
                        ref: createRef(''),
                        value: '',
                        width: 12, // x < 12
                        type: "password",
                        required: true,
                        placeholder: 'Enter password'
                    }
                }
            ],
            registerForm: [
                {
                    name: 'email',
                    attribute: {
                        label: "Email",
                        ref: createRef(''),
                        value: '',
                        width: 12, // x < 12
                        type: "text",
                        required: true,
                        placeholder: 'Enter email'
                    }
                },
                {
                    name: 'password',
                    attribute: {
                        label: "Password",
                        ref: createRef(''),
                        value: '',
                        width: 12, // x < 12
                        type: "password",
                        required: true,
                        placeholder: 'Enter password'
                    }
                },
                {
                    name: 'confirm_password',
                    attribute: {
                        label: "Confirm Password",
                        ref: createRef(''),
                        value: '',
                        width: 12, // x < 12
                        type: "password",
                        required: true,
                        placeholder: 'Enter confirm password'
                    }
                }
            ],
            isLogin: true // Login or Register
        };

        this.config = {
            googleIcon: Init.config.icons.google,
            facebookIcon: Init.config.icons.facebook
        }
    }

    responseFacebook = (response) => {
        console.log(response);
    }

    static contextType = NavigationContext;

    componentDidMount() {
        window.addEventListener('resize', function(event){});
        
    }

    componentDidUpdate() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    
    componentWillUnmount() {
        this.setState = (state,callback)=>{
            return;
        };

        window.removeEventListener('resize', () => {});
    }

    formUpdate() {

    }

    login() {
        const email = this.state.loginForm[0].attribute.ref.current.value;
        const password = this.state.loginForm[1].attribute.ref.current.value;

        ( async () => {
            const response = await authAPI.login(email, password);
            console.log(response);

            if( response.hasOwnProperty('errorCode') && response.errorCode === 0 ) {
                // Redirect with token as query
                // this.context.navigate(`/?token=${ response.data.token }`);
                window.location.href = `/?token=${ response.data.token }`;
            } else {
                // Popup
            }
        } )();
    }

    register() {
        const email = this.state.registerForm[0].attribute.ref.current.value;
        const password = this.state.registerForm[1].attribute.ref.current.value;
        const confirmPassword = this.state.registerForm[2].attribute.ref.current.value;

        ( async () => {
            const response = await authAPI.register(email, password, confirmPassword);
            console.log(response);

            if( response.hasOwnProperty('errorCode') && response.errorCode === 0 ) {
                // Redirect with token as query
                // this.context.navigate(`/?token=${ response.data.token }`);
                window.location.href = `/?token=${ response.data.token }`;
            } else {
                // Popup
            }
        } )();
    }

    toLogin() {
        const state = this.state;

        state.isLogin = true;

        this.setState({
            ...state
        });
    }

    toRegister() {
        const state = this.state;

        state.isLogin = false;

        this.setState({
            ...state
        });
    }

    openFacebookOauth() {

    }

    openGoogleOauth() {
        window.location.href = getGoogleOauthURL();
    }

    render() {
        return (
            <div className="login-page">
                <div className="login-container">
                    {
                        this.state.isLogin ? (
                            <>
                                <h1>
                                    Login to Denim House
                                </h1>
                                <Row>
                                    {
                                        this.state.loginForm.map( (item, index) => {
                                            return (
                                                <FormInput  
                                                    key={index}
                                                    attribute={item.attribute}
                                                    name={item.name} 
                                                    onChange={ (name, value) => this.formUpdate(name, value) } 
                                                />
                                            );
                                        } )
                                    }
                                </Row>
                                <PrimaryButton size="sm" customClass="login-item login-btn" onClick={ () => this.login() }>
                                    Login
                                </PrimaryButton>
                                <PrimaryButton size="sm" customClass="login-item login-btn" onClick={ () => this.toRegister() }>
                                    Sign Up
                                </PrimaryButton>
                                <SecondaryButton size="sm" customClass='login-item login-btn' onClick={() => this.openGoogleOauth()}>
                                    <img src={this.config.googleIcon} alt="Google" />
                                    Login with Google
                                </SecondaryButton>
                            </>
                        ) : (
                            <>
                                <h1> Sign Up </h1>
                                <Row>
                                    {
                                        this.state.registerForm.map( (item, index) => {
                                            return (
                                                <FormInput  
                                                    key={index}
                                                    attribute={item.attribute}
                                                    name={item.name} 
                                                    onChange={ (name, value) => this.formUpdate(name, value) } 
                                                />
                                            );
                                        } )
                                    }
                                </Row>
                                <PrimaryButton size="sm" customClass="login-item login-btn" onClick={ () => this.register() }>
                                    Sign Up
                                </PrimaryButton>
                                <PrimaryButton size="sm" customClass="login-item login-btn" onClick={ () => this.toLogin() }>
                                    Login
                                </PrimaryButton>
                            </>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default LoginPage;