import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import Init from '../../Init/Init';
import productAPI from '../../Data/Product';
import ProductCatalog from '../../Component/PagesComponent/ProductCatalog/ProductCatalog';
import PhotoCarousel from '../../Component/PagesComponent/PhotoCarousel/PhotoCarousel';

import './ComparisonPage.scss';

import { connect } from 'react-redux';
import groupActions from '../../Redux/Group/group-action';
import brandActions from '../../Redux/Brand/brand-action';
import SearchBar from '../../Component/Utilities/SearchBar/SearchBar';

const { getCategories } = brandActions;
const { getGroup } = groupActions;

const { FontAwesomeIcon, constants } = Init;

const mapDispatchToProps = (dispatch) => {
    return {
        getCategories: () => dispatch(getCategories()),
        getGroup: () => dispatch(getGroup())
    }
};

const ComparisonPage = (props) => {
    const templateCount = 4;
    const [data, setData] = useState([]);
    const [excludedProducts, setExcludedProducts] = useState([]);
    const location = useLocation();

    const [ toggleModal, setToggleModal ] = useState(false);
    const [ searchParam, setSearchParam ] = useState(0);
    const [ searchType, setSearchType ] = useState(productAPI.enum.fetchType.category);

    const searchText = useRef('');

    const template = Array.from(Array(templateCount).keys());

    const getProduct = async (productCode) => {
        const response = await productAPI.productDetail(productCode);

        if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
            for(let i in response.data.photos) {
                response.data.photos[i] = `${process.env.REACT_APP_API_PRODUCT_STORAGE}${response.data.product_code}/${response.data.photos[i]}`
            }

            return response.data;
        } else {
            return null;
        }
    }

    useEffect( () => {
        if( location.state.hasOwnProperty('productCode') ) {
            props.getCategories();

            (async () => {
                const response = await getProduct(location.state.productCode);
            
                if( response !== null ) {
                    setData([response]);
                }
            })();
        }
    }, []);

    useEffect( () => {
        if( data.length > 0 ) {
            setExcludedProducts( data.map( item => item.product_code ) );
        }
    }, [ data ]);

    const chooseProduct = (productCode) => {
        if( data.length > templateCount ) return ;
        
        // const lastId = data.length + 1;
        // const additionalData = {
        //     "id": lastId,
        //     "product_id": 1,
        //     "size_category_id": 5,
        //     "product_code": `TTWB${ lastId }`,
        //     "variant_name": "TIght Terry Windy Blues",
        //     "status": 1,
        //     "condition": "",
        //     "notes": "",
        //     "created_at": "2023-10-26T06:20:04.000Z",
        //     "updated_at": "2023-10-26T06:20:04.000Z",
        //     "photos": [],
        //     "product_name": "Tight Terry",
        //     "product_category_name": "Nudie Jeans",
        //     "product_quantities": {
        //         "70": {
        //             "price": 2000000,
        //             "quantity": 1,
        //             "size_name": "27",
        //             "discount_price": 0
        //         },
        //         "71": {
        //             "price": 2000000,
        //             "quantity": 1,
        //             "size_name": "28",
        //             "discount_price": 0
        //         },
        //         "72": {
        //             "price": 2000000,
        //             "quantity": 1,
        //             "size_name": "29",
        //             "discount_price": 0
        //         }
        //     },
        //     "size_category_name": "Jeans"
        // };

        ( async () => {
            const additionalData = await getProduct(productCode);

            if( additionalData !== null ) {
                setData([...data, additionalData]);
                closeModal();
            }
        } )();
    }

    const removeProduct = (index) => {
        let comparisonData = [...data];
        comparisonData.splice(index, 1);

        setData( [...comparisonData] );
    }

    const searchProduct = (keyword) => {
        // console.log(keyword);
        setSearchParam(keyword);
        setSearchType(productAPI.enum.fetchType.search);
    }

    const closeModal = () => {
        setToggleModal(false);
    }

    const openModal = () => {
        setSearchParam(0);
        setToggleModal(true);
    }

    const selectCategory = (id, fetchType) => {
        setSearchParam(id);
        setSearchType(fetchType);
    };

    return (
        <div className='comparison-page' id="comparison-page">
            {/* 
                1. Add template
                2. Empty container
                3. Filled container
                4. Flow mechanism
            */}
            <Modal className="catalog-modal" show={toggleModal} onHide={() => closeModal()}>
                <Modal.Header closeButton>
                    <Modal.Title> Catalog </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SearchBar 
                        refSearch={ searchText }
                        onKeywordChange={ (keyword) => searchProduct(keyword) }
                        show={ true }
                        customClass={`modal-search-bar`}
                    />
                    <ProductCatalog 
                        viewType={ constants.flags.catalogViewType.list }
                        selectCategory={(id, fetchType) => selectCategory(id, fetchType)} 
                        param={ searchParam } 
                        fetchType={ searchType } 
                        excludeProduct={ excludedProducts }
                        enablePick={ true }
                        onPick={ (productCode) => chooseProduct(productCode) }
                    />
                </Modal.Body>
            </Modal>
            <div className="template-container">
                {
                    template.map( (item, index) => {
                        if( data.hasOwnProperty(index) && data[index] !== null ) {
                            let product = data[index];

                            return (
                                <div key={index} className="template-box">
                                    {/* <div className="image-container">
                                        <img src="" alt="" />
                                    </div> */}
                                    <div className="btn-close-container">
                                        <button onClick={ () => removeProduct(index) } className="btn-close">
                                        </button>
                                    </div>
                                    <PhotoCarousel photos={product.photos} hideIndex={true} />
                                    <div className="product-name">
                                        { product.product_category_name } - { product.variant_name }
                                    </div>
                                    <div className="product-description">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        Parameter
                                                    </th>
                                                    <th>
                                                        Value
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        Product Code
                                                    </td>
                                                    <td>
                                                        { product.product_code }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Category
                                                    </td>
                                                    <td>
                                                        { product.product_name }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Size
                                                    </td>
                                                    <td>
                                                        {
                                                            `${Object.values( product.product_quantities ).map( item => item.size_name ).join(", ")} [${ product.size_category_name }]`
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Condition
                                                    </td>
                                                    <td>
                                                        { product.condition }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            );
                        } else {
                            return (
                                <button key={index} className="template-box empty" onClick={ () => openModal() }>
                                    + Add Product
                                </button>
                            );
                        }
                    } )
                }
            </div>
        </div>
    );
}

export default connect(null, mapDispatchToProps)(ComparisonPage);