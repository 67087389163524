import marqueeAPI from '../../Data/Marquee';
import * as actionTypes from './marquee-types';

const getMarquee = () => {
    return async dispatch => {
        const response = await marqueeAPI.marqueeList();
    
        if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
            dispatch(getMarqueeSuccess(response.data)); 
        }
    }
}

const getMarqueeSuccess = (data) => {
    return {
        type: actionTypes.MARQUEE_LIST,
        payload: {
            data
        }
    };
}

const marqueeActions = {
    getMarquee
}

export default marqueeActions;