import articlesData from '../../Data/Article';
import * as actionTypes from './article-types';

const loadArticle = (startId = 0, limit = 10, keyword = '', refresh = false) => {
    return dispatch => {
        console.log("Load Article", {
            startId,
            limit
        });

        articlesData(null, startId, limit, keyword).then( (response) => {
            if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                let { article, count } = response.data;

                dispatch(loadArticleSuccess({
                    data: article,
                    amount: count,
                    refresh
                }));
            }
        } );
    }
}

const loadArticleSuccess = (data) => {
    return {
        type: actionTypes.ARTICLE_LIST,
        payload: {
            ...data
        }
    }
} 

const loadArticleDetail = (id) => {
    return dispatch => {
        articlesData(id).then( (response) => {
            if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                let { data } = response;

                dispatch(loadArticleDetailSuccess(data));
            }
        } );
    }
}

const loadArticleDetailSuccess = (data) => {
    return {
        type: actionTypes.ARTICLE_DETAIL,
        payload: {
            data
        }
    }
}

const articleActions = {
    loadArticle,
    loadArticleDetail
}

export default articleActions;