const TermsAndCondition = () => {
    return {
        header: {
            title: "Return Policy",
            description: [
                
            ]
        },
        list: [
            {
                title: "Return for Exchange Information",
                description: [
                    {
                        type: "list",
                        content: {
                            title: `Terms and Conditions return for exchange of items:`,
                            list: [
                                "All transactions are final, product refund only applies to products with major manufactory defect / flaw.",
                                "Purchased items are exchangeable into a different size available.",
                                "We have right to decline the exchange or refund if the stock selected is not available.",
                                "Product broke caused by delivery of the third party will be the responsibility of the expedition or the third party.",
                                "You have 3(three) days to return (point 1) or exchange your items (point 2). We will not accept any return of items after that.",
                                `Contact us via email (<a href="mailto:denimhouse.id@gmail.com" class="text-link">denimhouse.id@gmail.com</a>) or via our official WhatsApp customer service.`,
                                "You will receive an email confirmation containing your guidance instructions.",
                                "Once confirmed all exchanges are subject to availability, if not, a refund can be made. Entailed with returning the delivered items to DENIM HOUSE address as follows: [DENIM HOUSE, Komplek Daan Mogot Baru Jalan Jimbaran(Ruko) Blok LA-12A No. 11, Kalideres. RT.8/RW.12, Kalideres, Jakarta Barat, Kota Jakarta Barat, Daerah Khusus Ibukota Jakarta, Indonesia] on Monday - Saturday, 11:30 - 21:00 WIB (except during public holiday).",
                                "Please make sure the product, box, and all items included in one package are exactly as you received them with the tag still attached.",
                                "We have right to decline the exchange or refund if the item we receive is not the same as we deliver it to is not available."
                            ]
                        }
                    }
                ]
            }
        ]
    }
}

export default TermsAndCondition;