import api from './API';

const basePath = 'faq';
const pathList = {
    all: `/`
};

for(let key in pathList) {
    pathList[key] = basePath + pathList[key];
}

const FAQ = () => {
    return [
        {
            question: "When will my order be processed?", 
            // answer: "Right after payment was made and confirmed, we will shortly processed them.",
            answer: "The order will be processed right after payment was made and confirmed.",
            open: false
        },
        {
            question: "Do you take orders during weekend?", 
            answer: "Yes, we do. We will process your order in the weekend or public holiday.",
            open: false
        },
        {
            question: "What are the accepted payment methods?", 
            answer: `
            <ul>
                <li>
                    Bank Transfer
                </li>
                <li>
                    Virtual Account
                </li>
                <li>
                    Credit card (Visa and Master card)
                </li>
                <li>
                    Gopay
                </li>
                <li>
                    Ovo
                </li>
            </ul>
            `,
            open: false
        },
        {
            question: "Will I receive any confirmation after placing an order?", 
            answer: "A confirmation will be sent to you within 24 hours after payment. A following text via whatsapp will be sent regarding the shipping and the tracking information.",
            open: false
        },
        {
            question: "Can I cancel or change my order?", 
            answer: "Any order that has been submitted cannot be changed or cancelled due to the system. We take priority in completing your order as soon as possible to fit your needs. However, you can contact our Customer Service if you have any inquiries regarding this matter.",
            open: false
        },
        {
            question: "Customer Service Department", 
            answer: `
            <ul>
                <li>
                    E- mail: denimhouse.id@gmail.com
                </li>
                <li>
                    Whatsapp: +62 81218918925
                </li>
            </ul>
            `,
            open: false
        },
        {
            question: "How is the shipping policy?", 
            answer: `
            <p>
                Currently, we are very pleased to inform you that we ship to all across Indonesia. We have multiple shipping methods that you are free to choose. Feel free to contact us to request the shipping method.
            </p>
            <p>
                Please note that the orders generally take 1-2 business days to process in the warehouse before shipping. This processing time only applied to standard ground shipping.
            </p>
            `,
            open: false
        },
        {
            question: "How is the FREE shipping policy?", 
            answer: `
                <p>
                    Currently we had a free shipping promotion for order more than 1 million IDR, if your order is bellow 1 million IDR then we will contact you via whatsapp for the shipping fee.
                </p>

                <ul>
                    <li class="list-title">
                        JNE Next Day
                    </li>
                    <p>
                        You can expect your order to arrive the next day after we place them for delivery (Jakarta Area) and 1-2 business days (outside Jakarta) when placed before 3 PM Indonesia Western Time.
                    </p>
                    <li class="list-title">
                        JNE Regular
                    </li>
                    <p>
                        You can expect your order to arrive 1-3 business days when placed before 3 PM Indonesia Western Time
                    </p>
                    <li class="list-title">
                        Sicepat BEST
                    </li>
                    <p>
                        You can expect your order to arrive the next day after we place them for delivery (Jakarta Area)
                        and 1-2 business days (outside Jakarta) when placed before 3 PM Indonesia Western Time.
                    </p>
                    <li class="list-title">
                        Sicepat Regular
                    </li>
                    <p>
                        You can expect your order to arrive 1-3  business days when placed before 3 PM Indonesia Western Time
                    </p>
                    <li class="list-title">
                        Sameday Delivery
                    </li>
                    <p>
                        Orders after 12:00 will be processed the next day
                    </p>
                </ul>
            `,
            open: false
        }
    ];
};

const FAQList = async () => {
    try {
        const apiPath = pathList.all;
        const faq = await api.getCall(apiPath);

        return faq;
    } catch(e) {
        console.log(e.toString());
    }
};

const FAQAPI = {
    FAQList
}

export default FAQAPI;