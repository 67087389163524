import productAPI from '../../Data/Product';
import * as actionTypes from './group-types';

const getGroup = () => {
    return async dispatch => {
        const response = await productAPI.groupList();
    
        if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
            dispatch(getGroupSuccess(response.data)); 
        }
    }
}

const getGroupSuccess = (data) => {
    return {
        type: actionTypes.GROUP_LIST,
        payload: {
            data
        }
    };
}

const groupActions = {
    getGroup
}

export default groupActions;