import * as actionTypes from './marquee-types';

const INITIAL_STATE = {
    marqueeList: [],
    isLoaded: false
}

const marqueeReducer = (state = INITIAL_STATE, action) => {
    let { marqueeList, isLoaded } = state;
    
    switch(action.type) {
        case actionTypes.MARQUEE_LIST:
            marqueeList = action.payload.data.map( (item, index) => {
                return item.marquee_text;
            } );
            isLoaded = marqueeList.length > 0;

            return {
                ...state,
                marqueeList,
                isLoaded
            };
        default: 
            return state;
    }
};

export default marqueeReducer;