import api from './API';

const basePath = 'shipping';
const pathList = {
    deliveryServiceList: `/delivery-service`,
    cost: `/cost`,
    rates: `/rates`
};

for(let key in pathList) {
    pathList[key] = basePath + pathList[key];
}

const deliveryServiceList = async () => {
    try {
        const apiPath = pathList.deliveryServiceList;
        const response = await api.getCall(apiPath);

        return response;
    } catch(e) {
        return e;
    }
};

const getCost = async (destinationId, courierCode, totalQuantity) => {
    try {
        const apiPath = pathList.cost;
        const response = await api.postCall(apiPath, null, {
            destinationId,
            courierCode,
            totalQuantity
        });

        return response;
    } catch(e) {
        return e;
    }
}

const getRate = async (destinationAreaId, postalCode) => {
    try {
        const apiPath = pathList.rates;
        const response = await api.postCall(apiPath, null, {
            destinationAreaId,
            postalCode
        });

        return response;
    } catch(e) {
        return e;
    }
}

const shippingAPI = {
    deliveryServiceList,
    getCost,
    getRate
}

export default shippingAPI;