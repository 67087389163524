import React, { useState, useEffect } from 'react';

import ArticleList from '../../Component/PagesComponent/ArticleList/ArticleList';

import './ArticlePage.scss';
import ArticleCollection from '../../Component/PagesComponent/ArticlesCollection/ArticleCollection';

const ArticlePage = (props) => {
    const [limit, setLimit] = useState(10);
    
    useEffect( () => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    }, [])

    return (
        <div className='article-page'>
            <h1 className='article-page-title'>
                Artikel
            </h1>
            {/* <ArticleList limit={limit} /> */}
            <ArticleCollection limit={limit} />
        </div>
    );
}

export default ArticlePage;