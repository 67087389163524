import React, { useContext, useEffect, useState } from 'react';

import PrimaryButton from '../../Utilities/PrimaryButton/PrimaryButton';

import './OrderMessage.scss';
import NavigationContext from '../../../Context/NavigationContext';

const OrderMessage = (props) => {
    const [ transactionCode, setTransactionCode ] = useState('');
    const navigation = useContext(NavigationContext);
    const { navigate } = navigation;
    
    useEffect( () => {
        const { params } = navigation;

        if(params !== null && params.hasOwnProperty('transactionCode') && params.transactionCode) {
            setTransactionCode(params.transactionCode);
        }
    }, []);

    return (
        <div className="order-success-message">
            <h3 className='order-success-message-title'>
                Pesanan anda telah kami terima!
            </h3>
            <div className="order-success-message-text">
                Segera lakukan pembayaran pesanan Anda agar kami dapat memproses pesanan Anda. Anda dapat mengisinya kapanpun dengan scroll halaman kebawah dan klik “Pay Now”.
            </div>
        </div>
    );
}

export default OrderMessage;