import React from 'react';
import { Modal } from 'react-bootstrap';

import './QuickPeekModal.scss';

import ProductDetail from '../ProductDetail/ProductDetail';

const QuickPeekModal = (props) => {
    /**
     * Initiate variables
     */
    const { show, onHide } = props;

    const onClose = () => {
        onHide();
    }

    return (
        <Modal className="quick-peek-modal" show={show} onHide={() => onClose()}>
            <Modal.Header closeButton>
                <Modal.Title> Quick Peek </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ProductDetail productCode={show} />
            </Modal.Body>
        </Modal>
    );
}

export default QuickPeekModal;