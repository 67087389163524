import React, {Component, createRef, useContext, useState} from 'react';

import { Row } from 'react-bootstrap';

import FormInput from '../../Component/Utilities/FormInput/FormInput';
import PrimaryButton from '../../Component/Utilities/PrimaryButton/PrimaryButton';
import NavigationContext from '../../Context/NavigationContext';

import './OrderPage.scss';

const OrderSearch = ({ inputRef }) => {
    const context = useContext(NavigationContext);
    const { navigate } = context;

    const [ disableButton, setDisableButton ] = useState(true);

    const onChangeSearch = () => {
        let searchRef = inputRef.ref.current;
        let searchButtonDisabled = disableButton;

        if(searchRef.value !== '') {
            searchRef.classList.add('is-filled');
            searchButtonDisabled = false;
        } else {
            searchRef.classList.remove('is-filled');
            searchButtonDisabled = true;
        }

        if(searchButtonDisabled !== disableButton) {
            setDisableButton(searchButtonDisabled);
        }
    };

    const searchOrder = () => {
        let transactionCode = inputRef.ref.current.value;
        let target = `/order/${transactionCode}`;

        if(transactionCode !== '') {
            navigate(target, {
                replace: false,
                state: {}
            });
        }
    }

    return (
        <div className="order-search-page">
            <h3 className="order-search-title">
                Lacak Pesanan
            </h3>
            <div className="order-search-container">
                <Row>
                    <FormInput
                        name={'transaction_code'}
                        attribute={ inputRef }
                        onChange={ () => onChangeSearch() }
                    />
                </Row>
            </div>
            <div className="order-search-btn-container">
                <PrimaryButton disabled={ disableButton } customClass='order-search-btn' size='sm' onClick={ () => searchOrder() }>
                    Search
                </PrimaryButton>
            </div>
        </div>
    );
}

class OrderPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            transactionCode: {
                label: '',
                ref: createRef(),
                placeholder: 'Masukkan nomor pesanan anda',
                width: 12,
                required: true
            },
            searchButtonDisabled: true
        }
    }

    componentDidMount() {
        window.addEventListener('resize', function(event){});
        
    }

    componentDidUpdate() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    
    componentWillUnmount() {
        this.setState = (state,callback)=>{
            return;
        };

        window.removeEventListener('resize', () => {});
    }

    render() {
        return (
            <div className='order-page'>
                <OrderSearch inputRef={this.state.transactionCode} />
            </div>
        );
    }
}

export default OrderPage;