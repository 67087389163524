import React, { useState, useEffect } from "react";
import Init from "../../../Init/Init";

import authAPI from "../../../Data/Auth";
import UtilityFunction from "../../../Util/Util";

import AddressForm from '../../../Component/PagesComponent/AddressForm/AddressForm';
import PrimaryButton from '../../../Component/Utilities/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../../Component/Utilities/SecondaryButton/SecondaryButton';
import CustomToast from "../../Utilities/Toast/Toast";

import './AddressSection.scss';

import { connect } from "react-redux";
import { getAuthenticatedUser } from "../../../Redux/Auth/auth-action";
import shoppingCartActions from "../../../Redux/Shopping/shopping-action";

import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';

const GaTracker = ({
    action,
    label
}) => {
    const gaEventCategory = 'Checkout';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    gaEventTracker(action, label);
};

const { clearCart } = shoppingCartActions;
const { FontAwesomeIcon } = Init;
const { objectIsEmpty } = UtilityFunction;

const mapStateToProps = (state) => {
    return {
        identity: state.auth.identity
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        clearCart: (ownerCode) => dispatch(clearCart(ownerCode)),
        getAuthenticatedUser: () => dispatch(getAuthenticatedUser())
    }
}

const AddressSection = (props) => {
    const [open, setOpen] = useState(true);
    const [isLogin, setIsLogin] = useState(false);
    const [chosenAddress, setChosenAddress] = useState(0);
    const [addressData, setAddressData] = useState({});
    const [addressList, setAddressList] = useState([]);

    const [toastContent, setToastContent] = useState({
        title: '',
        content: '',
        status: null,
        show: false
    });
    
    const [ deleteToast, showDeleteToast ] = useState(true);

    useEffect( () => {
        if(props.identity.hasOwnProperty('id') && props.identity.id > 0) {
            setIsLogin(true);
        } else {
            setIsLogin(false);
        }
    }, [props.identity]);

    useEffect( () => {
        if(isLogin) {
            closeAddressForm();
        } else {
            openAddressForm();
        }
    }, [isLogin]);

    useEffect(() => {
        props.chosenAddress.current = chosenAddress;
    }, [chosenAddress]);

    useEffect( () => {
        setAddressData(props.addressData);
    }, [props.addressData]);

    useEffect( () => {
        if(!open) {
            setAddressList(props.identity.address);
        }
    }, [open, props.identity]);

    const openAddressForm = (addressId = 0) => {
        showDeleteToast(false);

        let data = addressData;

        if(addressId > 0) {
            let address = addressList.find(item => item.id === addressId);

            data.name.value = address.receiver_name;
            data.email.value = address.receiver_email;
            data.phone.value = address.phone_number;

            data.province.value = address.province_id;
            data.regency.value = address.regency_id;
            data.district.value = address.district_id;
            data.village.value = address.village_id;
            
            // data.province.value = parseInt(address.province_id);
            // data.regency.value = parseInt(address.regency_id);
            // data.district.value = parseInt(address.district_id);
            // data.village.value = parseInt(address.village_id);

            data.postal_code.value = address.postal_code;
            data.street_address.value = address.street_address;

            GaTracker({
                action: `Click add address`,
                label: `${props.identity.customer_username} add address`
            });

            setChosenAddress(addressId);
        } else {
            if(!objectIsEmpty(data)) {
                data.name.value = '';
                data.email.value = '';
                data.phone.value = '';
    
                data.province.value = 0;
                data.regency.value = 0;
                data.district.value = 0;
                data.village.value = 0;
    
                data.postal_code.value = '';
                data.street_address.value = '';
    
                setChosenAddress(0);
            }
        }
        
        setAddressData({
            ...data
        });
        setOpen(true);
    };

    const closeAddressForm = (updateList = false) => {
        if(updateList) {
            props.getAuthenticatedUser();
        }
        
        GaTracker({
            action: `Close address form`,
            label: `${props.identity.customer_username} close address form`
        });

        setOpen(false);
    };

    const chooseAddress = (addressId) => {
        if( !deleteToast ) {
            GaTracker({
                action: `Choose address`,
                label: `${props.identity.customer_username} change address`
            });
        
            setChosenAddress(addressId);
        }
    };

    const saveAddress = () => {
        let requestData = {};
        
        for(let key in addressData) {
            let item = addressData[key];
            let value = item.ref.current.value;

            if(item.hasOwnProperty('prefix') && item.prefix !== '') {
                value = item.prefix + value;
            }

            requestData[key] = value;
        }

        (async() => {
            const response = await authAPI.addressSave(chosenAddress, requestData);

            if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                // closeAddressForm(true);
                GaTracker({
                    action: `Save address`,
                    label: `${props.identity.customer_username} save address`
                });
                
                showToast(true, 'Address has been updated!');
                props.getAuthenticatedUser();
            } else {
                // Display error if possible;
                let data = addressData;

                for(let index in response.data) {
                    let item = response.data[index];
                    let { 
                        // actual, 
                        field, 
                        message, 
                        // type 
                    } = item;

                    data[field].hasError = true;
                    data[field].errorMessage = message;
                }
                
                showToast(false, 'Failed to update address!');
                setAddressData({
                    ...data
                });
            }
        })();
    };

    const deleteAddress = (addressId) => {
        
        if( chosenAddress !== addressId || ! deleteToast ) {
            setChosenAddress(addressId);
            showDeleteToast(true);
        } else {
            showDeleteToast(false);

            (async() => {
                const response = await authAPI.addressDelete(chosenAddress);
    
                if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                    // closeAddressForm(true);
                    GaTracker({
                        action: `Delete address`,
                        label: `${props.identity.customer_username} delete address`
                    });
                    
                    showToast(true, response.message);
                    props.getAuthenticatedUser();
                } else {
                    showToast(false, response?.message);
                }
            })();
        }
    }

    const showToast = (success = false, message = '') => {
        const content = toastContent;

        if(success) {
            content.title   = 'Success';
            content.content = message;
        } else {
            content.title   = 'Failed';
            content.content = message;
        }

        content.status  = success;
        content.show = true;

        setToastContent({
            ...content
        });
    }

    const closeToast = () => {
        setToastContent({
            ...toastContent,
            show: false
        });
    };

    const closeDeleteToast = () => {
        showDeleteToast(false);
    };
    
    return (
        <>
            <CustomToast show={toastContent.show} 
                onClose={closeToast} 
                title={toastContent.title} 
                status={toastContent.status}
                customClass="account-modify-toast"
            >
                <div className="content">
                    { toastContent.content }
                </div>
            </CustomToast>
            <CustomToast show={deleteToast} 
                onClose={closeDeleteToast} 
                title={'Address Deletion'} 
                status={0}
                position="middle-center"
                toastClass="account-modify-toast"
            >
                <div className="content">
                    <div className="delete-desc-container">
                        Apakah anda yakin ingin menghapus alamat ini?
                    </div>

                    <div className="delete-address-info-container">
                        {
                             addressList.map( (item, index) => {
                                return item.id === chosenAddress ? (
                                    <div className={`address-item focus`}>
                                        {/* 
                                            1. Header: Name, Phone Number
                                            2. Content: Address, Location
                                        */}
                                        <div className="address-item-header">
                                            <span className="receiver-name">{ item.receiver_name }</span> <span className="receiver-phone-number">{ item.phone_number }</span>
                                        </div>
                                        <div className="address-item-content">
                                            <span className="address-content-item">
                                                { item.street_address }
                                            </span>, <span className="address-content-item">
                                                { item.village_name }
                                            </span>, <span className="address-content-item">
                                                { item.district_name }
                                            </span>, <span className="address-content-item">
                                                { item.regency_name }
                                            </span>, <span className="address-content-item">
                                                { item.province_name }
                                            </span>, <span className="address-content-item">
                                                { item.postal_code }
                                            </span>
                                        </div>
                                    </div>
                                ) : ''
                             })
                        }
                    </div>
                    
                    <div className="delete-button-container">                    
                        <PrimaryButton size="sm" customClass="btn-check-cart" onClick={() => deleteAddress(chosenAddress, true)}>
                            Yakin
                        </PrimaryButton>
                        <SecondaryButton size="sm" customClass="btn-close-toast" onClick={() => closeDeleteToast()}>
                            Batal
                        </SecondaryButton>
                    </div>
                </div>
            </CustomToast>
            {
                !open ? (
                    <div className={`address-list-container ${props.className}`}>
                        {
                            props.useTitle ? (
                                <h3 className="address-list-title">
                                    Pilih Alamat
                                </h3>
                            ) : ('')
                        }
                        <div className="address-list">
                            {
                                addressList.map( (item, index) => {
                                    return (
                                        <div key={index} className="address-item-container">
                                            <div className={`address-item ${chosenAddress === item.id ? 'focus' : ''}`} onClick={() => chooseAddress(item.id)}>
                                                {/* 
                                                    1. Header: Name, Phone Number
                                                    2. Content: Address, Location
                                                */}
                                                <div className="address-item-header">
                                                    <span className="receiver-name">{ item.receiver_name }</span> <span className="receiver-phone-number">{ item.phone_number }</span>
                                                </div>
                                                <div className="address-item-content">
                                                    <span className="address-content-item">
                                                        { item.street_address }
                                                    </span>, <span className="address-content-item">
                                                        { item.village_name }
                                                    </span>, <span className="address-content-item">
                                                        { item.district_name }
                                                    </span>, <span className="address-content-item">
                                                        { item.regency_name }
                                                    </span>, <span className="address-content-item">
                                                        { item.province_name }
                                                    </span>, <span className="address-content-item">
                                                        { item.postal_code }
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="btn-address-modify-container">
                                                <button className="btn-address-modify" onClick={() => openAddressForm(item.id)}>
                                                    <FontAwesomeIcon icon={['fas', 'pencil']} /> Ubah
                                                </button>
                                                <button className="btn-address-modify delete" onClick={() => deleteAddress(item.id, false)}>
                                                    <FontAwesomeIcon icon={['fas', 'trash']} /> Hapus
                                                </button>
                                            </div>
                                        </div>
                                    );
                                } )
                            }
                        </div>
                        <SecondaryButton size="sm" customClass="btn-open-address-form" onClick={() => openAddressForm()}>
                            + Tambah Alamat
                        </SecondaryButton>
                    </div>
                ) : (
                    <AddressForm useTitle={props.useTitle} formData={props.addressData} className={`${props.className}`}>
                        {
                            isLogin ? (
                                <div className='btn-address-form-container'>
                                    <PrimaryButton size="sm" onClick={ () => saveAddress() }>
                                        Simpan Alamat
                                    </PrimaryButton>
                                    <SecondaryButton size="sm" onClick={ () => closeAddressForm(true) }>
                                        Kembali Ke Pilihan Alamat
                                    </SecondaryButton> 
                                </div>
                            ) : ('')
                        }
                    </AddressForm>
                )
            }
        </>
    );
};

AddressSection.defaultProps = {
    useTitle: false,
    className: ''
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressSection);