import api from './API';

const basePath = 'payment';
const pathList = {
    manual: {
        path: `/manual`,
        subPath: {
            paymentConfirm: `/confirm/:param`
        }
    }
};

for(let key in pathList) {
    let pathItems = pathList[key];

    for(let key2 in pathItems.subPath) {
        pathItems[key2] = basePath + pathItems.path + pathItems.subPath[key2];
    }
    
    delete pathItems.subPath;
    pathList[key] = pathItems;
}

const manualPaymentConfirm = async (requestData) => {
    try {
        const apiPath = pathList.manual.paymentConfirm;
        const payment = await api.postCall(apiPath, requestData.transaction_code, requestData);

        return payment;
    } catch(e) {
        console.log(e.toString());
    }
};

const paymentAPI = {
    manualPaymentConfirm
}

export default paymentAPI;