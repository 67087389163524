import React from 'react';

import './SecondaryButton.scss';

const SecondaryButton = (props) => {
    return (
        <button className={`btn-custom-secondary ${props.size} ${props.disabled ? 'disabled' : ''} ${props.customClass}`} onClick={() => props.onClick()}>
            {props.children}
        </button>
    );
}

SecondaryButton.defaultProps = {
    disabled: false,
    customClass: '',
    onClick: () => {
        //
    },
    size: 'md'
}

export default SecondaryButton;