import React, { useEffect, useState } from 'react';
import './CatalogHeader.scss';

import Init from '../../../Init/Init';

const { icons } = Init.config;
const { flags } = Init.constants;

const CatalogHeader = (props) => {
    const [ viewType, setViewType ] = useState(null);
    const [ sortBy, setSortBy ] = useState(null);

    useEffect( () => {
        setViewType( props.viewType );
    }, [ props.viewType ]);

    useEffect( () => {
        setSortBy( props.sortBy );
    }, [ props.sortBy ]);

    const toggleView = (flag) => {
        setViewType(flag);
        props.onViewChange(flag);
    };

    const toggleSort = (flag) => {
        props.onSortChange(flag);
    }

    return (
        <div className="catalog-header">
            {/* 
                1. View Options (Left)
                2. Sort Dropdown (Right)
            */}
            <div className="view-options">
                <img className={`catalog-icon ${ viewType === flags.catalogViewType.grid ? 'active' : '' }`} src={ icons.grid } onClick={ () => toggleView(flags.catalogViewType.grid) } />
                <img className={`catalog-icon ${ viewType === flags.catalogViewType.list ? 'active' : '' }`} src={ icons.list } onClick={ () => toggleView(flags.catalogViewType.list) } />
            </div>
            <div className="sort-dropdown-container">
                <label className='sort-dropdown-label'> Sort By: </label>
                <select className='sort-dropdown' name="" id="" defaultValue={ sortBy } onChange={(event) => toggleSort(event.target.value)}>
                    <option value="best-seller">Best Seller</option>
                    <option value="recommended">Recommended</option>
                    <option value="chosen">Chosen</option>
                </select>
            </div>
        </div>
    );
}

export default CatalogHeader;