import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

import { isMobile } from 'react-device-detect';

// Constants
const constants = {
    flags: {
        transactionStatus: {
            waitingForPayment: 0,
            onProcess: 1,
            onDelivery: 2,
            completed: 3,
            rejected: 4,
            cancelled: 5
        },
        catalogViewType: {
            grid: 1,
            list: 2
        },
        allowTransaction: process.env.REACT_APP_ALLOW_TRANSACTION == true,
    },
    text: {
        transactionStatus: {
            0: 'Menunggu Pembayaran',
            1: 'Dalam Proses',
            2: 'Dalam Pengiriman',
            3: 'Selesai',
            4: 'Ditolak',
            5: 'Dibatalkan'
        },
        catalogViewType: {
            1: 'Grid View',
            2: 'List View'
        }
    },
}

var Init = {};

Init.constants = constants;

// Basic config
Init.config = {
    carousel: {
        swipeable: true,
        draggable: true,
        showDots: true,
        responsive: {
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 3,
              slidesToSlide: 3 // optional, default to 1.
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 2,
              slidesToSlide: 2 // optional, default to 1.
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            }
        },
        ssr: false, // means to render carousel on server-side.
        infinite: true,
        autoPlay: false,
        autoPlaySpeed: 99999,
        keyBoardControl: true,
        customTransition: "all .5",
        transitionDuration: 500,
        removeArrowOnDeviceType: [
            // "tablet", 
            // "mobile", 
            // "web"
        ],
        deviceType: "web",
        partialVisible: false,
        centerMode: false,
        containerClass: "carousel-container",
        dotListClass: "custom-dot-list-style",
        itemClass: "carousel-item-padding-40-px"
    },
    customImageFilter: {
        none: [
            1, 0, 0, 0, 0,
            0, 1, 0, 0, 0,
            0, 0, 1, 0, 0,
            0, 0, 0, 1, 0,
        ],
        grayscale: [
            0.85, 0, 0, 0, 0,
            0.85, 0, 0, 0, 0,
            0.85, 0, 0, 0, 0,
            0, 0, 0, 1, 0,
        ]
    },
    imageFolder: "/assets/images/",
    linktreeFolder: "/assets/images/linktree/",
    aboutUsFolder: "/assets/images/about-us/",
    imageLogo: "/assets/logo/logo.svg",
    imageLogoAlt: "/assets/logo/logo-alt.svg",
    iconFolder: "/assets/icons/",
    icons: {},
    aboutUs: {},
    isMobile: isMobile,
};

// About Us
Init.config.aboutUs.firstImage = Init.config.aboutUsFolder + "landscape-1.png";
Init.config.aboutUs.secondImage = Init.config.aboutUsFolder + "landscape-2.png";
Init.config.aboutUs.thirdImage = Init.config.aboutUsFolder + "landscape-3.png";
Init.config.aboutUs.fourthImage = Init.config.aboutUsFolder + "landscape-4.png";

// Icons
Init.config.icons.quickLook = Init.config.iconFolder + "quick-look-icon.png";
Init.config.icons.google = Init.config.iconFolder + "google-icon.svg";
Init.config.icons.facebook = Init.config.iconFolder + "facebook-icon.svg";
Init.config.icons.favicon = Init.config.iconFolder + "icon.svg";

Init.config.icons.check = Init.config.iconFolder + "check-icon.svg";
Init.config.icons.grid = Init.config.iconFolder + "grid-icon.svg";
Init.config.icons.ig = Init.config.iconFolder + "ig-icon.svg";
Init.config.icons.list = Init.config.iconFolder + "list-icon.svg";
Init.config.icons.love = Init.config.iconFolder + "love-icon.svg";
Init.config.icons.paymentCircle = Init.config.iconFolder + "payment-circle-icon.svg";
Init.config.icons.plus = Init.config.iconFolder + "plus-icon.svg";
Init.config.icons.search = Init.config.iconFolder + "search-icon.svg";
Init.config.icons.shop = Init.config.iconFolder + "check-icon.svg";
Init.config.icons.trash = Init.config.iconFolder + "trash-icon.svg";
Init.config.icons.user = Init.config.iconFolder + "user-icon.svg";

// Extensions
library.add(fab, fas, far);
Init.FontAwesomeIcon = FontAwesomeIcon;


export default Init;