import Init from "../Init/Init";

const SizeChart = () => {
    const { imageFolder } = Init.config;

    const chart =  [
        imageFolder + 'adidas-size-chart.jpg',
        imageFolder + 'adidas-slide-size-chart.jpg',
        imageFolder + 'nike-gs-size-chart.jpg',
        imageFolder + 'nike-men-size-chart.jpg',
        imageFolder + 'nike-women-size-chart.jpg'
    ];

    return chart;
}

export default SizeChart;