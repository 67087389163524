import React, { useState, useEffect } from 'react';

import productAPI from '../../../Data/Product';
import ProductBox from '../../../Component/PagesComponent/ProductBox/ProductBox';
import ProductCard from '../../../Component/PagesComponent/ProductCard/ProductCard';
import QuickPeekModal from '../../../Component/PagesComponent/QuickPeekModal/QuickPeekModal';

import './ProductCatalog.scss';
import LoadingPage from '../../Utilities/LoadingPage/LoadingPage';

import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';

import { connect } from "react-redux";

import Init from '../../../Init/Init';

const { flags } = Init.constants;

const mapStateToProps = (state) => {
    return {
        brand: state.brand.categoryList,
        brandLoaded: state.brand.isLoaded
    }
};

const GaTracker = ({
    action,
    label
}) => {
    const gaEventCategory = 'Product Catalog';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    gaEventTracker(action, label);
};

const ProductCatalog = (props) => {
    const [ products, setProducts ] = useState([]);
    const [ productCode, setVariantId ] = useState(0);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ categories, setCategories ] = useState([]);

    const { param, fetchType, title, excludeProduct, selectCategory, enablePick = false } = props;

    const { brand, category, search, storefront, group } = productAPI.enum.fetchType;

    useEffect( () => {
        setCategories(props.brand);
    }, [props.brand]);

    useEffect( () => {
        setIsLoading(false);
    }, [products]);

    useEffect( () => {
        ( async () => {
            if(isLoading) {
                let response = {};

                switch(fetchType) {
                    case brand:
                        response = await productAPI.categoryProductsByBrand(param);
                        // response = {
                        //     errorCode: 0,
                        //     message: "Brands have been fetched",
                        //     data: []
                        // };

                        // if( props.brandLoaded ) {
                        //     response.data = props.brand;
                        // }

                        break;
                    case category: 
                        response = await productAPI.categoryProducts(param);
                        break;
                    case search:
                        response = await productAPI.productSearch(param);
                        break;
                    case storefront:
                        response = await productAPI.storefrontProducts(param);
                        break;
                    case group:
                        response = await productAPI.groupProducts(param);
                        break;
                    default:
                        break;
                }

                if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                    let data = response.data;

                    if(excludeProduct.length > 0) {
                        for(let i = 0;i < data.length;i++) {
                            let item = data[i];

                            if(excludeProduct.includes(item.product_code)) {
                                data.splice(i, 1);
                            }
                        }
                    } 

                    setProducts(data);
                }
            }
        } )();
    }, [isLoading]);

    useEffect( () => {
        if(!isLoading && param) {
            setIsLoading(true);
        }
    }, [param, excludeProduct]);

    const onPeek = (id) => {
        setVariantId(id);
    }

    const onChoose = (productCode) => {
        props.onPick(productCode);
    }

    const closeModal = () => {
        setVariantId(0);
    }

    const chooseCategory = (id, brand_name) => {
        GaTracker({
            action: `Click brand`,
            label: `Brand: ${brand_name}`
        });

        selectCategory(id, brand);
    }

    if(isLoading) {
        return (
            <LoadingPage />
        )
    } else if(products.length === 0) {
        return (
            <div className="product-catalog-loading-container">
                <div className="product-category-label">
                    Choose brands:
                </div>
                <div className="product-category-logos">
                    {
                        categories.map( (item, index) => {
                            return (
                                <div key={index} className="product-category-logo" onClick={ () => chooseCategory(item.id, item.product_category_name) }>
                                    <img src={ process.env.REACT_APP_API_PRODUCT_CATEGORY_STORAGE + item.logo_path } />
                                </div>
                            )
                        } )
                    }
                </div>
            </div>
        )
    } else {
        return (
            <>
                <QuickPeekModal show={productCode} onHide={ () => closeModal() } />
                {
                    title === null ? '' : (
                        <h3 className="product-catalog-title">
                            { title }
                        </h3>
                    )
                }
                {
                    props.viewType === flags.catalogViewType.grid ? (
                        <div className="product-catalog-grid">
                            {
                                products.map( (item, index) => {
                                    return (
                                        <ProductBox key={index} item={item} enablePick={ props.enablePick } onChoose={ (productCode) => onChoose(productCode) } onPeek={ (productCode) => onPeek(productCode) } />
                                    )
                                } )
                            }
                        </div>
                    ) : (
                        <div className="product-catalog-list">
                            {
                                products.map( (item, index) => {
                                    return (
                                        <ProductCard key={index} item={item} enablePick={ props.enablePick } onChoose={ (productCode) => onChoose(productCode) } onPeek={ (productCode) => onPeek(productCode) } />
                                    )
                                } )
                            }
                        </div>
                    )
                }
            </>
        );
    }

}

ProductCatalog.defaultProps = {
    title: null,
    fetchType: productAPI.enum.fetchType.category,
    param: '',
    excludeProduct: [],
    enablePick: false
}

export default connect(mapStateToProps)(ProductCatalog);