import React, { useState } from "react";
import Init from "../../../../Init/Init";
import { Collapse } from 'react-bootstrap';

import './CategoryOption.scss';
import productAPI from "../../../../Data/Product";

import useAnalyticsEventTracker from '../../../../GoogleAnalytics/useAnalyticsEventTracker';

const GaTracker = ({
    action,
    label
}) => {
    const gaEventCategory = 'Brand';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    gaEventTracker(action, label);
};

const CategoryOption = (props) => {
    const { item, onChoose } = props;
    const [ open, setOpen ] = useState(false);
    const { FontAwesomeIcon } = Init;

    const chooseCategory = (id, category) => {
        GaTracker({
            action: `Click category`,
            label: `Category: ${ category }`
        });

        onChoose(id, productAPI.enum.fetchType.category);
    };

    const chooseBrand = (id, brand) => {
        GaTracker({
            action: `Click brand`,
            label: `Brand: ${ brand }`
        });

        onChoose(id, productAPI.enum.fetchType.brand);
    }

    return (
        <div className="category-items">
            <button className="category-item-header"
                    onClick={() => setOpen(!open)} 
                    aria-controls="example-collapse-text"
                    aria-expanded={item.open}>
                <h6 className='category-item-name' onClick={ () => chooseBrand(item.id, item.product_category_name) }>
                    { item.product_category_name }
                </h6>
                <FontAwesomeIcon className="category-item-icon" icon={['fas', (open ? 'chevron-up' : 'chevron-down')]} />
            </button>
            <Collapse in={open}>
                <div className='category-products'>
                    {
                        item.category_products.map( (v, k) => {
                            return (
                                <button key={k} className="category-product-item" onClick={ () => chooseCategory(v.id, v.product_name)}>
                                        { v.product_name }
                                </button>
                            )
                        })
                    }
                </div>
            </Collapse>
        </div>
    );
}

export default CategoryOption;