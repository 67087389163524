import React, {Component} from 'react';
import {Col, FormLabel, FormGroup, FormControl, Form, InputGroup} from 'react-bootstrap';
import { FileUploader } from 'react-drag-drop-files';
import Init from '../../../Init/Init';

import UtilityFunction from '../../../Util/Util';

import './FormInput.scss';

const { objectIsEmpty } = UtilityFunction;
class FormInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileObj: ''
        };
    }

    static getDerivedStateFromProps(props) {
        return {
            name: props.name,
            attribute: props.attribute,
        };
    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    updateInput(event, input) {
        const onChangeForm = this.props.onChange;
        const state = this.state;

        if(state.attribute.type !== 'file') {
            if(input.current.value) {
                state.attribute.hasError = false;
                input.current.classList.add('is-filled');
                this.setState({
                    ...state
                });
            } else {
                input.current.classList.remove('is-filled');
            }
    
            onChangeForm(input.current.name, input.current.value);
        } else {
            this.getBase64(event, (result) => {
                state.attribute.ref.current = {
                    value: result
                };
                onChangeForm(state.name, result);

                state.fileObj = result;
                this.setState({
                    ...state
                });
            });
        }

    }

    renderSwitch() {
        const {FontAwesomeIcon} = Init;

        const state = this.state;

        let inputName = state.name;
        let properName = inputName.split('_').join(' ');
        let baseValue = state.attribute.hasOwnProperty('value') && state.attribute.value !== null ? state.attribute.value : '';
        let inputType = state.attribute.hasOwnProperty('type') && state.attribute.type ? state.attribute.type : "text";
        let inputAs = "";
        let isRequired = state.attribute.hasOwnProperty('required') && state.attribute.required;
        let inputPlaceholder = state.attribute.hasOwnProperty('placeholder') ? state.attribute.placeholder : "Enter your " + state.attribute.label.toLowerCase();
        let options = inputType === 'select' && this.props.hasOwnProperty('options') && this.props.options !== undefined ? this.props.options : []; // { value: '', label: '' }
        let autoComplete = state.attribute.hasOwnProperty('autoComplete') ? state.attribute.autoComplete.toString() : "true";
        let hasError = state.attribute.hasOwnProperty('hasError') ? state.attribute.hasError : false;
        let errorMessage = state.attribute.hasOwnProperty('errorMessage') ? state.attribute.errorMessage.replace(inputName, properName) : `Invalid ${properName} input`;
        let prefix = state.attribute.hasOwnProperty('prefix') ? state.attribute.prefix : '';
        let size = state.size;

        baseValue = baseValue ? baseValue.replace(prefix, '') : '';

        if(isRequired) {
            inputPlaceholder += '';
        } else {
            inputPlaceholder += ' (Optional)';
        }

        switch(state.attribute.type) {
            case "select":
                inputAs = "select";
                baseValue = baseValue !== '' ? baseValue : 0;
                
                return (
                    <>
                        <Form.Select
                            // as={inputAs}
                            name={inputName}
                            type={inputType} 
                            ref={state.attribute.ref}
                            // defaultValue={baseValue}
                            required={isRequired}
                            rows={5} 
                            onChange={(event) => this.updateInput(event,state.attribute.ref)}
                            isInvalid={hasError}
                            className='is-filled'
                            size={size}
                        >
                            {
                                options.map( (value, key) => {
                                    let optionValue = value.value;

                                    return (
                                        <option key={key} value={optionValue} 
                                            selected={optionValue === baseValue}
                                        >
                                            { value.label }
                                        </option>
                                    );
                                } )
                            }
                        </Form.Select>
                        <FormControl.Feedback type="invalid">
                            { errorMessage }
                        </FormControl.Feedback>
                    </>
                );
            case "textarea":
                inputAs = "textarea";
                return (
                    <>
                        <FormControl 
                            name={inputName}
                            as={inputAs}
                            type={inputType} 
                            ref={state.attribute.ref} 
                            defaultValue={baseValue}
                            required={isRequired}
                            placeholder={inputPlaceholder}
                            rows={5} 
                            onChange={(event) => this.updateInput(event, state.attribute.ref)}
                            autoComplete={autoComplete}
                            isInvalid={hasError}
                            size={size}
                        />
                        <FormControl.Feedback type="invalid">
                            { errorMessage }
                        </FormControl.Feedback>
                    </>
                );
            case 'file':
                let allowedFileTypes = ['JPG', 'PNG'];
                return (
                    <>
                        
                        <FileUploader
                            handleChange={(event) => this.updateInput(event, null)} 
                            name={inputName} 
                            classes={'form-control file-upload'}
                            types={allowedFileTypes}
                        >
                            {
                                this.state.fileObj !== '' ? (
                                    <>
                                        <img className='file-upload-image' src={this.state.fileObj} alt="" />
                                    </>
                                ) : (
                                    <>
                                        <FontAwesomeIcon className='icon' icon={['fas', 'inbox']} />
                                        <h5 className='label'>
                                            Click or drag a file to this area to upload.
                                        </h5>
                                        <div className="types">
                                            Supported Format: { allowedFileTypes.join(', ') }
                                        </div>
                                    </>
                                )
                            }
                        </FileUploader>
                        <FormControl.Feedback type="invalid">
                            { errorMessage }
                        </FormControl.Feedback>
                    </>
                );
            case 'text':
            case 'number':
            default:
                inputAs = "input";
                return (
                    <>
                        <InputGroup>
                            {
                                prefix !== '' ? (
                                    <InputGroup.Text>{ prefix }</InputGroup.Text>
                                ) : ''  
                            }
                            <FormControl 
                                name={inputName}
                                as={inputAs}
                                type={inputType} 
                                ref={state.attribute.ref} 
                                defaultValue={baseValue}
                                required={isRequired}
                                placeholder={inputPlaceholder}
                                onChange={(event) => this.updateInput(event, state.attribute.ref)}
                                autoComplete={autoComplete}
                                isInvalid={hasError}
                                className={ baseValue ? 'is-filled' : '' }
                                size={size}
                            />
                            <FormControl.Feedback type="invalid">
                                { errorMessage }
                            </FormControl.Feedback>
                        </InputGroup>
                    </>
                );
        };
    }

    render() {
        let isRequired = this.state.hasOwnProperty('attribute') && this.state.attribute.hasOwnProperty('required') && this.state.attribute.required;

        return this.state.hasOwnProperty('attribute') && (
            <Col lg={this.state.attribute.width}>
                <FormGroup className={'form-items'}>
                    {
                        this.state.attribute.label !== '' ? (
                            <FormLabel htmlFor="name"> 
                                { this.state.attribute.label + (isRequired ? "*" : "") } 
                            </FormLabel>
                        ) : ''
                    }
                    {
                        this.renderSwitch()
                    }
                    
                </FormGroup>
            </Col>
        );
    }
}

FormInput.defaultProps = {
    attribute: {
        label: "",
        value: "",
        width: 12,
        placeholder: "Enter here",
        type: 'text',
        autoComplete: false,
        size: 'md'
    },
    onChange: (name, value) => {
        //
    }
}

export default FormInput;