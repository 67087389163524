import api from './API';

const basePath = 'product';
const pathList = {
    category: `/category`,
    categoryProducts: `/category/:param`,
    storefront: `/storefront`,
    storefrontProducts: `/storefront/:param`,
    productDetail: `/:param`,
    group: `/group`,
    groupProducts: `/group/:param`
};

const fetchType = {
    brand: 1,
    category: 2,
    search: 3,
    storefront: 4,
    group: 5
}

for(let key in pathList) {
    pathList[key] = basePath + pathList[key];
}

const categoryList = async () => {
    try {
        const apiPath = pathList.category;
        const category = await api.getCall(apiPath);

        return category;
    } catch(e) {
        console.log(e.toString());
    }
};

const categoryProductsByBrand = async (argParam) => {
    try {
        const apiPath = pathList.categoryProducts;
        const type = fetchType.brand;

        const categoryProducts = await api.getCall(apiPath, type, {
            data: argParam
        });

        return categoryProducts;
    } catch(e) {
        console.log(e);
    }
};

const categoryProducts = async (argParam) => {
    try {
        const apiPath = pathList.categoryProducts;
        const type = fetchType.category;

        const categoryProducts = await api.getCall(apiPath, type, {
            data: argParam
        });

        return categoryProducts;
    } catch(e) {
        console.log(e);
    }
};

const productSearch = async (argParam) => {
    try {
        const apiPath = pathList.categoryProducts;
        const type = fetchType.search;

        const categoryProducts = await api.getCall(apiPath, type, {
            data: argParam
        });

        return categoryProducts;
    } catch(e) {
        console.log(e);
    }
};

const storefrontList = async () => {
    try {
        const apiPath = pathList.storefront;
        const storefront = await api.getCall(apiPath);

        return storefront;
    } catch(e) {
        console.log(e.toString());
    }
};

const storefrontProducts = async (argParam, argRequest = {}) => {
    try {
        const apiPath = pathList.storefrontProducts;
        const storefrontProducts = await api.getCall(apiPath, argParam, argRequest);

        return storefrontProducts;
    } catch(e) {
        console.log(e.toString());
    }
}

const productDetail = async (argParam) => {
    try {
        const apiPath = pathList.productDetail;
        const product = await api.getCall(apiPath, argParam);

        return product;
    } catch(e) {
        console.log(e.toString());
    }
}

const groupList = async () => {
    try {
        const apiPath = pathList.group;
        const group = await api.getCall(apiPath);

        return group;
    } catch(e) {
        console.log(e.toString());
    }
};

const groupProducts = async (argParam) => {
    try {
        const apiPath = pathList.groupProducts;
        const groupProducts = await api.getCall(apiPath, argParam);

        return groupProducts;
    } catch(e) {
        console.log(e.toString());
    }
}

const productAPI = {
    enum: {
        fetchType
    },
    categoryList,
    categoryProductsByBrand,
    categoryProducts,
    productSearch,
    storefrontList,
    storefrontProducts,
    productDetail,
    groupList,
    groupProducts
}

export default productAPI;