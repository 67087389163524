import React, {Component, useState, useEffect} from 'react';
import './Faqpage.scss';

import { Row, Col, Accordion } from 'react-bootstrap';
import CollapseBox from '../../Component/PagesComponent/CollapseBox/CollapseBox';

import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return {
        FAQ: state.FAQ.FAQList,
        FAQLoaded: state.FAQ.isLoaded
    }
};

const Faqpage = (props) => {
    const [ list, setList ] = useState([]);
    
    useEffect( () => {
        window.addEventListener('resize', function(event){});
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        if( props.FAQLoaded ) {
            setList(props.FAQ);
        } else {
            setList([]);
        }
    }, [props.FAQLoaded]);

    const handleToggle = (index) => {
        let listCopy = list;

        listCopy[index].open = !listCopy[index].open;

        setList([
            ...listCopy
        ]);
    };

    /**
     * 1. Container
     * - Dropdown List
     * - Dropdown
     * - Description
     */
    return (
        <div className="faq-page">
            <div className="faq-header">
                <h2 className='faq-title'> FAQ </h2>
            </div>
            <Row className="question-group">
                <Accordion>
                {
                    list.map( (item, index) => {
                        return(
                            <CollapseBox key={index} boxNumber={index} list={item} onToggle={ () => handleToggle(index) } />
                        );
                    } )
                }
                </Accordion>
            </Row>
        </div>
    );
};

export default connect(mapStateToProps)(Faqpage);