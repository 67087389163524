import shoppingCartAPI from '../../Data/ShoppingCart';
import * as actionTypes from './shopping-types';

import Cookie from 'universal-cookie';

const cookies = new Cookie();

const addToCart = (ownerCode, productItem) => {
    return dispatch => {
        let authToken = cookies.get('authToken');

        if(authToken !== undefined && authToken !== null && authToken !== '') {
            shoppingCartAPI.call.addToCart(authToken, productItem).then( (response) => {
                if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                    ownerCode = response.data.customer_code;
                    dispatch(addToCartSuccess(ownerCode, productItem));
                }
            } );

        } else {
            dispatch(addToCartSuccess(ownerCode, productItem));
        }
    }
}

const addToCartSuccess = (ownerCode, productItem) => {
    return {
        type: actionTypes.ADD_TO_CART,
        payload: {
            ownerCode,
            productItem
        }
    }
}

const removeFromCart = (ownerCode, productVariantId) => {
    return dispatch => {
        let authToken = cookies.get('authToken');

        if(authToken !== undefined && authToken !== null && authToken !== '') {
            shoppingCartAPI.call.removeCartProduct(authToken, productVariantId).then( (response) => {
                if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                    ownerCode = response.data.customer_code;
                    dispatch(removeFromCartSuccess(ownerCode, productVariantId));
                }
            } );
        } else {
            dispatch(removeFromCartSuccess(ownerCode, productVariantId));
        }
    }
}

const removeFromCartSuccess = (ownerCode, productVariantId) => {
    return {
        type: actionTypes.REMOVE_FROM_CART,
        payload: {
            ownerCode,
            variantId: productVariantId
        }
    };
}

const adjustQty = (ownerCode, variantId, value) => {
    return dispatch => {
        if(value === 0) {
            dispatch(removeFromCart(ownerCode, variantId));
        } else {
            dispatch(adjustQtySuccess(ownerCode, variantId, value));
        }
    }    
}

const adjustQtySuccess = (ownerCode, variantId, value) => {
    return {
        type: actionTypes.ADJUST_QTY,
        payload: {
            ownerCode,
            variantId: variantId,
            qty: value
        }
    }
}

const loadCart = (ownerCode = 'guest') => {
    return dispatch => {
        let cartData;
        let authToken = cookies.get('authToken');
        let loadFromLocal = false;

        if(authToken !== undefined && authToken !== null && authToken !== '') {
            shoppingCartAPI.call.loadUserCart(authToken).then( (response) => {
                if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                    cartData = response.data.cart;
                    ownerCode = response.data.customer_code;

                    // console.log(`Response API CALL: ${JSON.stringify(response)}`);
                    // console.log(authToken);
                    
                    dispatch(loadCartSuccess(ownerCode, cartData));
                } else {
                    loadFromLocal = true;
                }
            } );
        } else {
            loadFromLocal = true;
        }

        if(loadFromLocal) {
            cartData = shoppingCartAPI.controller.get(ownerCode);
            dispatch(loadCartSuccess(ownerCode, cartData));
        }
    }
}

const loadCartSuccess = (ownerCode = 'guest', cartData) => {
    return {
        type: actionTypes.LOAD_CART,
        payload: {
            ownerCode,
            cartData
        }
    }
} 

const clearCart = (ownerCode = 'guest') => {
    return {
        type: actionTypes.CLEAR_CART,
        payload: {
            ownerCode
        }
    }
}

const shoppingCartActions = {
    addToCart,
    removeFromCart,
    adjustQty,
    loadCart,
    clearCart
}

export default shoppingCartActions;