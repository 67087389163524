import React, { useState, useEffect } from 'react';
import './Marquee.scss';

import Marquee from 'react-fast-marquee';

import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return {
        marquee: state.marquee.marqueeList,
        marqueeLoaded: state.marquee.isLoaded
    }
}

const CustomMarquee = (props) => {
    const [ marquee, setMarquee ] = useState({
        data: [],
        isLoaded: false
    });
    
    useEffect( () => {
        setMarquee({
            data: props.marquee,
            isLoaded: props.marqueeLoaded
        });
    }, [ props.marqueeLoaded ]);

    return (
        <Marquee
            gradient={false}
            speed={100}
            className='marquee'
        >
            {
                marquee.isLoaded &&
                marquee.data.map( (item, index) => {
                    return (
                        <div key={index} className='marquee-item'>
                            { item }
                        </div>
                    );
                } )
            }
        </Marquee>
    );
}

export default connect(mapStateToProps)(CustomMarquee);